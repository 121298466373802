
.tooltiptext {
  width: auto;
  max-width: 180px;
  background-color: rgb(251, 251, 251);
  color: #2B96DC;
  text-align: left;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid  #2B96DC;
}

