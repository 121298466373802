.allAddressModal {
  margin: 10% auto;
  background-color: #ffffff;
  width: 100%;
  height: auto;

  // overflow-y: auto;
  .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    margin-top: 20px;
    margin-left: 38px;
    color: #333333;

    @media (max-width: 600px) {
      font-size: 16px;
      margin-top: 10px;
      margin-left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .straightLine {
    height: 1px;
    width: 100%;
    background-color: #ced4da;
    margin-top: 10px;
    margin-bottom: 20px;

    @media (max-width: 600px) {
      margin-bottom: 15px;
    }
  }

  .bottomLinks {
    display: flex;
    justify-content: space-between;
    margin-left: 40px;

    .addNew {
      cursor: pointer;
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #2B96DC;
    }

    .buttons {
      display: flex;
      margin-right: 37px;
      margin-bottom: 20px;

      // padding: 10px;
      .back {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #333333;
        opacity: 0.6;
        margin-right: 15px;
        cursor: pointer;
      }

      .done {
        cursor: pointer;
        background: #2B96DC;
        border: 1px solid rgba(226, 228, 232, 0.4);
        box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);
        border-radius: 4px;
        color: #ffffff;
        width: 116.19px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 10px;

      }
    }
  }

  .selectAddress {
    // font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #33334f;
    margin-left: 38px;
  }

  .addressContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .addressDiv {
    width: 90%;

    // border: 1px solid black;
    .container {
      margin-top: 20px;
      width: 100%;
      height: 64px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #ffffff;
      border: 1px solid #dee2e6;
      border-radius: 3px;

      .addressDiv {
        display: flex;

        .address {
          .name {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #333333;
          }

          .add {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #333333;
            opacity: 0.6;
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2B96DC;

        .edit,
        .delete {
          margin-right: 5px;
          margin-left: 5px;
          font-family: "Red Hat Display", serif;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          text-align: right;
          text-decoration-line: underline;
          color: #2B96DC;
        }
      }
    }
  }
}


.edit,
.delete {
  cursor: pointer;
}