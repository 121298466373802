.project-category-div{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .project-category-card{
        background-color: #2B96DC;
        height: 100px;
        margin: 10px;
        color:white;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        border-radius: 10px;
        width: 350px;
        padding: 10px;
        transition: 0.5s;
    }
    .project-category-card:hover{
        transform: scale(1.05);
    }
    .project-category-card:active{
        transform: translateY(5px);
    }
}


.photo-view-div{
    display: inline;
    text-align: center;
    .photo-viewer-card{
        height: 200px;
        width: 250px;
        margin: 10px;
        border-radius: 5px;
        transition: 0.3s;
    }

    .photo-viewer-card:hover{
        transform: scale(1.05);
    }
}
