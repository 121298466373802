.Modal {
  .ModalData {
    background-color: #fff;
  }
  .close {
    position: relative;
    left: 95%;
    top: 10px;
    cursor: pointer;

    @media (max-width: 600px) {
      left: 10px;
      top: 10px;
    }
  }
}
