
.tooltiptext {
  width: 160px;
  background-color: rgb(62, 62, 62);
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  font-size: 11px;
}

