$primary-color: #2995DB;
$secondary-color: #333333;
$border-grey: #e5e5e5;
$text-color: #333333;
$m-gray:#555555;
$black-color:#000000;
$white-color:#ffffff;
$green-color:#00C389;
$stripe-color:#F8F9FA;
$border-color:#EDEDED;
$complementary-primary-color:#DC712B;
$complementary-hover-color:#bd5612;


