.flex-container {
    display: flex;
    justify-content: space-between; /* Space between the two divs */
    align-items: flex-start; /* Align items at the top */
  }
  
  .cad-viewer {
    width: 70%; /* Make the viewer occupy 70% of the modal's width */
    height: 400px; /* Set a height for your 3D viewer */
    background-color: #f0f0f0; /* Light gray background for visualization */
  }
  
  .testing-content {
    width: 30%; /* Take up 30% of the modal's width */
    padding-left: 16px; /* Add spacing between the 3D viewer and "Testing" content */
    display: flex;
    align-items: center; /* Center the text vertically */
    justify-content: center; /* Center the text horizontally */
    background-color: #e0e0e0; /* Light gray background for visualization */
  }
  