.shuffle-text {
    font-size: 18px;
    text-align: center;
    margin: 20px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
}

.shuffle-text.visible {
    opacity: 1;
    transform: translateX(0);
}