@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital@0;1&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Red Hat Display", serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6f8;
}

code {
  font-family: "Red Hat Display", serif, sans-serif;
}

input:focus {
  box-shadow: none !important;
}

body {
  margin: 0px;
  padding: 0px;
}

.navbar {
  position: sticky !important;
}

/* =================Tailwind-Start==================== */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* =================Tailwind-End==================== */

/* *{
  border:2px solid red;
} */

.no-caret::after {
  display: none !important;
}


