.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.show-accordiion {
  visibility: visible;
  /* border: 2px solid red ,3px solid green; */
}

.show-accordiion input,
.show-accordiion select {
  @apply border
}

.admin-panel-container input {
  @apply p-1 my-1 text-sm
}




td {
  min-height: 40px;
}

input[type="date"] {
  @apply border !important;
}


.container-item-list li {
  @apply my-[-3px]
}

/* .container-item-list 
{
  @apply grid grid-cols-3
} */
/* .buttons-to-border
{
  border: none;
  border-top: 1px solid lightslategray;
} */

/* Add this to your global.css */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #808080;
}

.animate-shimmer {
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}


