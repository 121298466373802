/* Styling for the radio box container */
.pr-radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }
  
  /* Styling for the label and box */
  .btn.box-in {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px 12px;  /* Reduced padding for smaller height */
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    width: 100%;
    cursor: pointer;
    height: 40px; /* Smaller height */
  }
  
  .btn.box-in:hover {
    background-color: #f7f7f7;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Styling for the check icon */
  .triangle-0 {
    margin-right: 8px;
  }
  
  .tick-icon {
    width: 16px;  /* Smaller icon */
    height: 16px;
  }
  
  /* Styling for the price */
  .price-sp {
    font-size: 14px; /* Smaller font size */
    font-weight: bold;
    color: #333;
    margin-right: 15px;
  }
  
  /* Styling for business name */
  .vld-bx {
    font-size: 14px; /* Smaller font size */
    color: #555;
    margin-right: 15px;
  }
  
  /* Styling for shipment status */
  .ship-bx {
    font-size: 14px; /* Smaller font size */
    color: #888;
  }
  
  /* Optional: Adding active state when selected */
  .pr-radio.selected .btn.box-in {
    border-color: #007bff;
    background-color: #f0f8ff;
  }
  