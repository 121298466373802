@import "variable";
@import "mixin";

// login page

#cad-analyzer {
  height: 650px;
}

.unread-messages {
  background: red;
  color: white;
  border-radius: 4px;
  position: relative;
  bottom: 6px;
  padding: 2px 8px;
  font-size: 12px;
}


.cs-chat-container {
  height: 90%;
}

.scrollbar-container {
  height: 90%;
}

.attachment {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  text-decoration: underline;
}

.cross-attachment {
  cursor: pointer;
  float: right;
}

.ant-tabs-nav-wrap {
  position: fixed !important;
  top: 20px;
}

.ant-drawer-header {
  visibility: hidden;
}

.outgoing {
  text-align: right;
  font-size: 10px;
  padding-top: 6px;
}

.incomming {
  text-align: left;
  font-size: 10px;
  padding-top: 6px;
}

.ant-drawer-content-wrapper {
  width: 40% !important;
}

.cs-loader--content {
  width: auto;
  height: auto;
  padding-top: 2.6em;
  position: fixed !important;
  right: 8% !important;
  top: 40% !important;
  color: black !important;
  font-weight: 700 !important;
  z-index: 100 !important;
}

.attachment-tile {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 0 0.7em 0.7em 0.7em;
  color: rgba(0, 0, 0, .87);
  background-color: #c6e3fa;
}

.attachment-type {
  position: absolute;
  top: 592px;
  // z-index: 105;
  width: 38px;
  cursor: pointer;
  opacity: 0;
}

.custom-modal-style {
  width: 90vw;
  height: 750px;
}

.sm-container {
  // max-width: 1140px;
  width: 100%;
  margin: 0 auto;
}

.md-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.fx-100 {
  flex: 0 0 100px;
}

.fx-180 {
  flex: 0 0 180px;
}

a {
  text-decoration: none;
}

.change-login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.email-and-change-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.form-label-email {
  margin: 0 0 8px 0;
  padding: 0;
  display: inline-block;
  color: rgba(51, 51, 51, 0.7);
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
}

.login-text {
  color: #0D6EFD;
  cursor: pointer;
  font-weight: 600;
}

.mb-3 {
  margin-bottom: 25px;
}

.green-fill {
  background-color: $green-color !important;
  border-color: $green-color !important;
}

.green-border {
  border-color: $green-color !important;
}

.green-line {
  &::before {
    background: $green-color !important;
  }
}

.grey-line {
  &::before {
    background: rgb(214, 214, 214) !important;
  }
}

.grey-fill {
  background-color: rgb(173, 173, 173) !important;
  border-color: rgb(173, 173, 173) !important;
}

.green-border {
  border-color: rgb(173, 173, 173) !important;
}

.op-1 {
  opacity: 1 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.mr-3 {
  margin-right: 30px !important;
}

.hightlight {
  background: #ffff00;
}

.cstm-check {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  width: 25px;
  height: 25px;
  border-bottom: solid 15px transparent;
  border-right: solid 15px transparent;
  border-left: solid 15px #2B96DC;
  border-top: solid 15px #2B96DC;
  position: absolute;
  left: 0;
  top: 0;

  img {
    position: absolute;
    left: -11px;
    top: -9px;
    z-index: 11;
  }
}

.check-ac {
  border-color: $primary-color !important;
}

input,
select,
textarea {
  border-radius: 8px !important;
}

select:focus {
  border-color: $primary-color !important;
  box-shadow: none !important;
}

// checkbox css
input[type="checkbox"]+label {
  display: block;
  margin: 0;
  cursor: pointer;
  padding: 0;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]+label:before {
  content: "";
  border: 1px solid silver;
  background: $white-color;
  border-radius: 3px;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding-left: 3px;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}

input[type="checkbox"]+label:active:before {
  transform: scale(0);
}

input[type="checkbox"]:checked+label:before {
  background-color: #ffffff;
  border-color: silver;
  content: url(./images/tick.svg);
  line-height: 19px;
}

input[type="checkbox"]:disabled+label:before {
  transform: scale(1);
  border-color: #aaa;
}

input[type="checkbox"]:checked:disabled+label:before {
  transform: scale(1);
  background-color: rgb(239, 239, 239);
  border-color: rgb(220, 220, 220);
}

input[type="checkbox"]:disabled+label:before {
  transform: scale(1);
  background-color: rgb(239, 239, 239);
  border-color: rgb(220, 220, 220);
}

// checkbox css close

// input file button

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #2B96DC;
  padding: 10px 10px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  width: 120px;
  margin-top: 6px;

  span {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
}

// input file button close

// radio css

.rd-box input[type="radio"]:checked,
.rd-box input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.rd-box input[type="radio"]:checked+label,
.rd-box input[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.rd-box input[type="radio"]:checked+label:before,
.rd-box input[type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.rd-box input[type="radio"]:checked+label:after,
.rd-box input[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: $primary-color;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.rd-box input[type="radio"]:checked+label:before,
.rd-box input[type="radio"]:checked+label:before {
  content: "";
  border-color: $primary-color;
}

.rd-box input[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.rd-box input[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

// radio css close

// cm check

.box-in {
  background: #fff;
  border: 1px solid rgba(226, 228, 232, 0.4);
  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);
  padding: 12px 10px 12px 18px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  color: $secondary-color;
  margin: 0;

  .triangle-0 {
    position: absolute;
    left: 0;
    top: 0;
  }

  .bx-icon {
    width: 32px;
    height: 32px;
    border: 1.2px solid $secondary-color;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
}

.quote-summary {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

@media (max-width: 576px) {
  .quote-summary {
    display: unset;
    flex-wrap: unset;
    grid-gap: unset;
  }
}

.pr-checked {
  label {
    border-color: #2B96DC;
    box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);
  }

  .triangle-0 {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    border-bottom: solid 15px transparent;
    border-right: solid 15px transparent;
    border-left: solid 15px #2B96DC;
    border-top: solid 15px #2B96DC;

    img {
      position: absolute;
      left: -11px;
      top: -9px;
      z-index: 1;
    }
  }
}

// cm check close

// common address box

.ad-box {
  display: flex;
  flex-wrap: wrap;
  background: $white-color;
  padding: 15px;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  margin-bottom: 15px;

  .rd-left {
    flex: 0 0 20px;
    max-width: 20px;
    display: inline-flex;
    padding-top: 5px;

    label {
      opacity: 1 !important;
    }
  }

  .ad-text {
    flex: 0 0 calc(100% - 120px);
    max-width: calc(100% - 120px);
    padding: 0 10px;

    .name {
      font-size: 13px;
      color: $secondary-color;
      font-weight: 400;
    }

    p {
      font-size: 13px;
      line-height: 18px;
      color: $secondary-color;
      opacity: 0.6;
    }
  }

  .action-text {
    flex: 0 0 100px;
    max-width: 100px;

    ul {
      display: inline-flex;
      margin: 0;
      padding: 0;
    }

    li {
      list-style: none;
      position: relative;
      padding: 0 10px;

      &::before {
        content: "";
        width: 1px;
        height: 12px;
        background: $primary-color;
        position: absolute;
        top: 8px;
        right: 0;
      }

      &:last-child {
        &::before {
          content: unset;
        }
      }
    }

    a {
      font-weight: 400;
      font-size: 13px;
      color: $primary-color;
    }
  }
}

// common address box close

.common-bx {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 30px 30px 30px 40px;
  position: relative;
  width: 100%;
}

.pr-edit-btn {
  @include blue-btn;
  position: absolute;
  right: 10px;
  top: 10px;
  width: auto;
  z-index: 99;
  cursor: pointer;
}

.MuiAccordionSummary-content {
  margin: 24px 0 !important;
  width: 100%;
  flex-direction: column;
}

.login-main {
  // background: #242834 url(images/login-bg.png) ;
  background: #242834;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;

  .left-shape {
    max-width: 205px;
    position: absolute;
    left: 0;
    top: 0;

    img {
      width: 100%;
    }
  }

  .right-top-shape {
    max-width: 320px;
    position: absolute;
    right: 0;
    top: 0;

    img {
      width: 100%;
    }
  }

  .right-bt-shape {
    max-width: 244px;
    position: absolute;
    left: 0;
    bottom: 0;

    img {
      width: 100%;
    }
  }

  .login-in {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // padding: 60px 0 60px 0;
    // align-items: center;
    // border:2px solid red;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    min-height: 100vh;
    place-items: center;

    @media (max-width:768px) {
      grid-template-columns: repeat(1, 1fr);
      // border:2px solid red;
      // padding-top: 200px;
      // min-height: 80vh;
      align-content: center;
      gap: 20px;
      // border:2px solid red;
    }

    .log-left {
      flex: 1;
      padding-right: 30px;

      .logo-tp {
        @media (min-width:768px) {
          position: relative;
          bottom: 20px;
        }


      }

      h2 {
        font-size: 40px;
        margin: 0;
        padding: 0;
        color: #fff;
        font-weight: 700;
        max-width: 440px;
      }

      p {
        font-size: 16px;
        margin: 30px 0 0 0;
        padding: 0;
        color: #fff;
        font-weight: 400;
        max-width: 440px;
        line-height: 24px;
      }

      .log-points {
        margin: 30px 0 0 0;

        ul {
          margin: 0;
          padding: 0;
        }

        li {
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          margin: 0 0 20px 0;
          display: flex;
          align-items: center;

          .icon {
            flex: 0 0 24px;
            max-width: 24px;
            border-radius: 50%;
            height: 24px;
            background: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
          }
        }
      }
    }

    .log-right {
      flex: 0 0 540px;
      max-width: 540px;
      background-color: #fff;
      padding: 30px 60px 40px 60px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      position: relative;
      height: fit-content;

      &::before {
        width: 190px;
        height: 371px;
        background: url(images/corner-four.svg) no-repeat;
        content: "";
        position: absolute;
        left: -190px;
        // bottom: 80px;
      }

      .form-heading {
        font-size: 20px;
        font-weight: 700;
        color: $secondary-color;
      }

      .login-btn {
        display: flex;
        gap: 20px;
        margin: 20px 0;
      }

      .login-form-bx {
        display: inline-block;
        width: 100%;
        position: relative;
        z-index: 1;

        .form-label {
          margin: 0 0 8px 0;
          padding: 0;
          display: inline-block;
          color: rgba(51, 51, 51, 0.7);
          font-weight: 400;
          font-size: 13px;
        }

        .form-control {
          width: 100%;
          background: #fff;
          border-radius: 3px;
          border: 1px solid #eaedf0;
          padding: 10px 15px;
          display: inline-block;
        }

        .form-control:focus {
          box-shadow: none !important;
        }

        .forgot-bar {
          text-align: right;

          a {
            font-size: 13px;
            color: #2b96dc;
          }
        }

        .btn-submit {
          @include blue-btn;
          display: inline-block;
          text-align: center;
        }
      }

      .signin-holder {
        margin: 30px 0 0 0;
        position: relative;
        z-index: 1;

        h6 {
          color: $secondary-color;
          font-size: 12px;
          text-align: center;
          position: relative;
          margin-bottom: 40px;

          span {
            position: relative;
            background: #fff;
            padding: 2px 8px;
          }

          &:before {
            content: "";
            width: 100%;
            height: 1px;
            background: #ddd;
            position: absolute;
            left: 0;
            right: 0;
            top: 8px;
          }
        }

        .sign-social {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;

          a {
            flex: 0 0 48%;
            max-width: 48%;
            padding: 10px 15px;
            border-radius: 4px;
            font-size: 15px;
            text-decoration: none;
            display: inline-flex;
            justify-content: center;
            color: #fff;

            span {
              margin-right: 6px;
              font-size: 18px;
              height: 16px;
              position: relative;
              top: 2px;

              i {
                vertical-align: top;
              }
            }
          }

          .fb-box {
            background: #2d4486;

            &:hover {
              background: #253a75;
            }
          }

          .ld-box {
            background: #007ab6;

            &:hover {
              background: #006191;
            }
          }
        }

        .signup-bx {
          font-size: 13px;
          text-align: center;
          color: $secondary-color;
          margin: 40px 0 50px 0;

          a {
            color: $primary-color;
            text-decoration: none;
          }
        }
      }
    }

    .password-validation {
      padding-top: 15px;
      font-size: 14px;

      p,
      li {
        color: rgba(51, 51, 51, 0.6);
      }
    }

    .names-field {
      display: flex;
      gap: 20px;
    }

    .forgot-section {
      .return-bar {
        margin-bottom: 35px;

        a {
          text-decoration: none;
          color: $secondary-color;
          font-size: 13px;

          span {
            margin-right: 4px;
          }
        }
      }

      .form-heading {
        margin-bottom: 10px;
      }

      p {
        font-size: 13px;
        color: $secondary-color;
        line-height: 18px;
        margin-bottom: 40px;
      }
    }

    .sign-up-bx {
      .login-form-bx {
        .sign-name {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .left-bx {
            flex: 0 0 48%;
            max-width: 48%;
          }
        }

        .help-block {
          padding-top: 15px;

          p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            color: rgba($secondary-color, 60%);
          }

          ul {
            margin: 10px 0 0 0;
            padding: 0;
          }

          li {
            list-style: inside;
            font-size: 14px;
            color: rgba($secondary-color, 60%);
          }
        }
      }

      .check-signup {
        display: flex;
        flex-wrap: wrap;

        label {
          flex: 0 0 20px;
          max-width: 20px;
        }

        p {
          font-size: 14px;
          color: rgba(51, 51, 51, 0.6);
          flex: 0 0 calc(100% - 20px);
          max-width: calc(100% - 20px);
          padding-left: 10px;
          line-height: 18px;

          a {
            color: $primary-color;
          }
        }
      }

      .signin-holder {
        .signup-bx {
          margin: 100px 0 40px 0;
        }
      }
    }
  }
}




// login page close

// dashboard css

.top-bar {
  background: #2B96DC;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 64px;

  .logo-pt {
    flex: 0 0 150px;
    max-width: 150px;

    img {
      max-width: 115px;
    }
  }

  .menu-holder {
    flex: 0 0 calc(100% - 150px);
    max-width: calc(100% - 150px);
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .main-menu {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;
      margin-right: 10px;

      li {
        list-style: none;
        margin-right: 40px;
        position: relative;

        &:last-child {
          margin-right: 0;
        }

        .tp-menu {
          color: #fff;
          text-decoration: none;
          font-weight: 500;
          font-size: 14px;
          position: relative;
          cursor: pointer;

          &:before {
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid rgb(255, 255, 255);
            content: unset;
            position: absolute;
            bottom: -23px;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .active {
          &:before {
            content: "";
          }
        }
      }
    }

    .right-menu {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        list-style: none;
        margin-left: 50px;
        position: relative;

        .count {
          background: #e1340d;
          font-size: 10px;
          padding: 0px 4px;
          border-radius: 17px;
          color: #fff;
          position: absolute;
          top: -6px;
          right: -17px;
        }

        &:first-child {
          margin-left: 0;
        }

        a {
          color: #fff;
          text-decoration: none;
          font-weight: 500;
          font-size: 14px;
        }

        .dropdown-toggle {
          background: transparent;
          border: none;
          padding: 0;
          font-size: 14px;

          .u-icon {
            margin-right: 5px;
          }

          &:after {
            content: unset;
          }

          &:focus {
            box-shadow: unset;
          }
        }

        .dropdown-item {
          color: $secondary-color;
        }
      }
    }
  }
}

.intro-bar {
  position: sticky;
  top: 64px;
  background: #fff;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 10;
  border-bottom: 1px solid $border-color;

  .int-left {
    flex: 0 0 50%;
    max-width: 50%;
  }



  .int-right {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 26px;
    align-items: center;

    .chat-btn {
      @include blue-btn;
      width: 177px;
      text-align: center;
    }
  }

  .int-right-view {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 26px;
    align-items: center;

    .chat-btn {
      @include blue-btn;
      width: 177px;
      text-align: center;
    }
  }

  .dropdown-toggle {
    padding: 0;
    background: transparent;
    color: $primary-color;
    border: none;
    font-weight: 400;
    font-size: 13px;

    &:after {
      border-top: 0.4em solid;
      border-right: 0.4em solid transparent;
      border-left: 0.4em solid transparent;
      position: relative;
      top: 2px;
    }
  }

  .int-right {
    .white-btn {
      // width: 100%;
      border: none;
      background: #ffffff;
      border-radius: 4px;
      padding: 10px 20px;
      color: #2B96DC;
      font-size: 15px;
      transition: 0.4s;
      text-decoration: none;
      border: 1px solid #dee2e6;
      margin-right: 10px;

      &:hover {
        background: #edf2f4;
      }
    }
  }

  .search-bar {
    // background-color: blue;
    max-width: 240px;
    display: inline-flex;
    width: 100%;
    border: 1px solid #eaedf0;
    border-radius: 3px;
    align-items: center;
    padding: 10px 10px;

    .form-control,
    .form-select {
      font-weight: 400;
      font-size: 14px;
      border: none;
      color: $secondary-color;
      opacity: 0.7;
      padding: 0 10px 0 0;
    }

    .form-select {
      padding: 5px;
    }

    .btn {
      background: none;
      border: none;
      padding: 0;
      width: 14px;
      height: 14px;

      img {
        vertical-align: top;
      }
    }
  }
}

.dashboard-holder {
  width: 100%;
  padding: 30px 20px;

  .dashboard-in {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    padding: 20px 50px 20px 60px;

    .dash-left {
      flex: 0 0 55%;
      max-width: 55%;

      .ds-heading {
        font-size: 28px;
        margin: 0 0 40px 0;
        padding: 0;
        font-weight: 400;
        line-height: 39px;
      }

      p {
        font-size: 16px;
        margin: 0;
        padding: 0;
        color: rgba(51, 51, 51, 0.6);

        span {
          position: relative;
          top: 4px;

          img {
            vertical-align: top;
          }
        }
      }

      .ds-btn {
        margin: 40px 0 80px 0;

        a {
          background: #2B96DC;
          text-decoration: none;
          color: #fff;
          border-radius: 4px;
          box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);
          max-width: 280px;
          width: 100%;
          display: inline-block;
          text-align: center;
          padding: 10px 0;
        }
      }

      .ds-link {
        a {
          font-size: 16px;
          color: #2B96DC;
          font-weight: 500;

          span {
            position: relative;
            top: 3px;
            margin-right: 3px;

            img {
              vertical-align: top;
            }
          }
        }
      }
    }

    .dash-img {
      flex: 0 0 45%;
      max-width: 45%;

      img {
        width: 100%;
      }
    }
  }
}

// dashboard css close

// steps css

.MuiAccordionSummary-root {
  padding: unset !important;
}

.process-bar-bx {
  position: relative;
  padding-left: 40px;
  padding-bottom: 40px;
  padding-top: 5px;

  &::before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .process-ico {
    background-color: $green-color;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -15px;
    border: 2px solid $green-color;
    top: 0;
    box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);

    img {
      max-width: 18px;
    }
  }

  h5 {
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 18px;
    color: $secondary-color;
  }


  .process-thumbs {
    p {
      font-size: 13px;
      margin: 0;
      padding: 0;
      color: $text-color;
    }
  }
}

// .upload-req {

//   &::before {
//     content: "";
//     width: 1px;
//     height: 100%;
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
// }

// .green-line-upload-req {
//   position: relative;
//   padding-left: 40px;
//   padding-bottom: 50px;
//   padding-top: 5px;

//   &::before {
//     content: "";
//     width: 1px;
//     height: 100%;
//     position: absolute;
//     left: 0;
//     top: 0;
//     background-color: $green-color !important;
//   }

// }

.upload-req {
  position: relative;

  p {
    font-size: 14px;
    margin: 0;
    padding: 0;
    color: $text-color;
  }

  // &::before {
  //   content: "";
  //   width: 1px;
  //   height: 100%;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   background-color: $green-color !important;
  // }
  .process-ico {
    background-color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -15px;
    top: 0;
    border: 2px solid $green-color;
    box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);

    img {
      max-width: 18px;
    }
  }

  h5 {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 18px;
    color: $secondary-color;
  }

  .upload-box {
    background: #f8f9fa;
    border: 1.5px dashed rgba(109, 109, 167, 0.3);
    border-radius: 8px;
    min-height: 160px;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5px;

    .inp-txt {
      color: #6e6e82;
      font-size: 16px;
      margin: 3px 0;
    }

    p {
      font-weight: 400;
      font-size: 13px;
      color: rgba($secondary-color, 40%);
      margin: 10px 0 0 0;
    }
  }

  .df-text {
    margin-top: 20px;

    h5 {
      margin: 0 0 6px 0;
      padding: 0;
      font-size: 18px;
      color: $secondary-color;
    }

    .tx-area {
      font-size: 16px;
      color: $m-gray;
      padding: 10px 15px;
      border-radius: 3px;
      border: 1px solid $border-color;
      width: 100%;
      height: 130px;
      resize: none;

      &:focus {
        outline: none;
      }
    }
  }

  .sample-box {
    background: #f4f6f8;
    padding: 12px 16px;
    margin-top: 32px;
    border: 1px solid $border-grey;
    border-radius: 8px;
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;

    .text {
      font-weight: 400;
      font-size: 14px;
      padding-right: 16px;
      color: $text-color;
    }

    .upload-btn {
      padding: 12px 16px;
      color: #ffffff;
      border: 1px solid #dee2e6;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-color;
    }
  }

  .saved-quote {
    margin-top: 20px;

    .quote-lft {
      p {
        font-weight: 400;
        font-size: 13px;
        color: $secondary-color;
        opacity: 0.6;

        span {
          position: relative;
          top: 3px;

          img {
            vertical-align: top;
          }
        }
      }
    }
  }
}

.order-pay-box {
  position: relative;
  // padding-left: 40px;
  padding-bottom: 50px;
  padding-top: 5px;

  h5 {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 18px;
    color: $secondary-color;
    opacity: 0.4;
  }

  .all-address {
    padding: 10px 15px;
    background: $white-color;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      font-weight: 500;
      font-size: 13px;
      text-decoration: none;
      color: $primary-color;
    }
  }

  .order-ico {
    background-color: $white-color;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -15px;
    top: 0;
    border: 2px solid #adadad;
    z-index: 1;
    box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);
  }

  .order-deatils-bx {
    .billing-box {
      border: 1px solid #dee2e6;
      border-radius: 3px;
      padding: 20px 10px;

      h6 {
        font-weight: 500;
        font-size: 16px;
        margin: 0;
        padding: 0;
        color: $secondary-color;
      }

      .billing-form {
        background: #f8f9fa;
        border-radius: 3px;
        padding: 25px 8px;
        margin-top: 15px;

        .fieldsContainer {
          display: flex;
          gap: 10px;
        }

        .mobile {
          display: flex;
          gap: 10px;

          input {
            width: 100%;
          }

          .radioContainer {
            display: flex;
            justify-content: space-around;
            // display: flex;
            // justify-content: center;
            // align-items: center;
          }
        }

        .company {
          input {
            width: 100%;
          }

          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap:10px;
          margin-left:5px
        }

        .industry {
          border: 1px solid #eaedf0;
          border-radius: 3px;
          font-size: 14px;
          color: #333333;
          height: 35px;
        }

        .col-md-3,
        .col-md-6 {
          padding-left: 6px;
          padding-right: 6px;
        }

        label {
          font-weight: 400;
          font-size: 12px;
          margin: 0 0 5px 0;
          padding: 0;
          color: $text-color;
          opacity: 0.7;
        }

        .form-control,
        .form-select {
          border: 1px solid #eaedf0;
          border-radius: 3px;
          font-size: 14px;
          color: #333333;
        }

        .radio-btns {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .one {
            margin-right: 20px;

            label {
              width: 100%;
              opacity: 1 !important;
              padding: 0 0 0 25px !important;
              font-size: 14px !important;
            }
          }
        }
      }

      .shipping-info {
        margin-top: 30px;

        h6 {
          margin: 0 0 10px 0;
        }

        .apply-check {
          display: flex;
          align-items: center;
          background: #f8f9fa;
          padding: 25px 30px;

          p {
            font-weight: 500;
            font-size: 14px;
            margin: 0;
            padding: 3px 0 0 10px;
            color: $secondary-color;
          }
        }
      }
    }

    .quote-right {
      .qoute-box {
        border: 1px solid rgba(226, 228, 232, 0.4);
        box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);
        border-radius: 4px;

        h6 {
          font-weight: 500;
          font-size: 16px;
          margin: 0 0 15px 0;
          padding: 20px 15px 0 15px;
        }

        .pd-items {
          padding: 0 15px;

          // .fileDetails{
          //   width: 400px;
          //   height: 400px;
          //   border: 1px solid black;
          // }
          .item-box {
            border: 1px solid #dee2e6;
            border-radius: 3px;
            padding: 10px 15px;
            margin-bottom: 15px;

            .item-bar {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 10px;

              .left {
                flex: 0 0 calc(100% - 80px);
                max-width: calc(100% - 80px);
                display: inline-flex;

                .img-box {
                  flex: 0 0 40px;
                  max-width: 40px;
                  height: 40px;
                  background: #eff1f3;
                }

                .qt-text {
                  flex: 1;
                  max-width: 100%;
                  padding-left: 10px;

                  h6 {
                    margin: 0;
                    padding: 0;
                  }
                }
              }

              .right {
                flex: 0 0 80px;
                max-width: 80px;
                font-weight: 700;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: right;
              }
            }
          }

          .subtotal-bx {
            padding-bottom: 15px;

            ul {
              margin: 0;
              padding: 0;
            }

            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 500;
              font-size: 14px;
              margin-bottom: 15px;
              color: $secondary-color;
            }
          }
        }

        .total-amt {
          border-top: 1px solid $border-color;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .check-sub {
        margin-top: 20px;

        .agree-check {
          display: flex;
          flex-wrap: wrap;

          label {
            flex: 0 0 20px;
            max-width: 20px;
          }

          p {
            flex: 0 0 calc(100% - 20px);
            max-width: calc(100% - 20px);
            font-weight: 400;
            font-size: 13px;
            padding-left: 10px;
          }
        }
      }
    }
  }
}

.privacy-text {
  font-size: 13px;
  color: $text-color;
  padding: 20px 25px;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 30px;
  width: 100%;
  text-align: left;
}

// steps css close

// step two css

.price-box {
  .total-price {
    background: #f8f9fa;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 16px;
    border: 1px solid rgba(226, 228, 232, 0.4);
    border-radius: 4px;
    margin-bottom: 12px;

    span {
      font-size: 18px;
      font-weight: 700;
      color: $secondary-color;
    }
  }

  .button-bx {
    margin-top: 10px;

    .process-btn {
      @include blue-btn;
      display: inline-block;
      text-align: center;
    }

    .linkBtnContainer {
      width: 400px;
      margin-top: 15px;
      // display: flex;
      // justify-content: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 25px;

      .linkBtn {
        width: 190px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #DEE2E6;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.price-tabs {
  display: flex;
  gap: 10px;

  .box-pr {
    width: 100%;
  }

  .box-in {
    padding: 12px 6px 12px 6px;
    min-height: 100px;

    .price-sp {
      width: 100%;
      display: inline-block;
      text-align: center;
      font-size: 20px;
      position: relative;
      font-weight: 500;
      color: #555555;
      margin-bottom: 10px;

      &::before {
        content: "";
        width: 21px;
        height: 1px;
        background: #dee2e6;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -5px;
      }
    }

    .vld-bx {
      font-size: 12px;
      width: 100%;
      display: inline-block;
      color: rgba(85, 85, 85, 0.87);
    }

    .ship-bx {
      width: 100%;
      display: inline-block;
      font-size: 12px;
      color: rgba(85, 85, 85, 0.6);
    }
  }
}

.btm-buttons {
  // display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;

  .btm-buttons-container {
    display: flex;
    justify-content: center;

    >div {
      padding-right: unset !important;
      margin-bottom: 10px;
    }
  }

  .br-btn {
    // @include border-btn;
    @include blue-btn;
    text-align: center;
    flex: 0 0 31.33%;
    max-width: 31.33%;
    padding: 8px 10px;
    font-size: 14px;

    .ico {
      position: relative;
      top: 5px;
      margin-right: 2px;

      img {
        vertical-align: top;
      }
    }
  }
}

.step-to-upload {
  flex-wrap: wrap;

  .up-icon {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-bottom: 15px;
  }

  .up-text {
    flex: 0 0 100%;
    max-width: 100%;
    display: inline-flex;
    align-items: center;
  }

  .file-button {
    label {
      color: $primary-color;
      padding-left: 5px;
      cursor: pointer;
    }
  }
}

.product-thumb {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 20px 20px;
  margin-top: 30px;

  .pro-bar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .product-name {
      flex: 0 0 calc(100% - 380px);
      max-width: calc(100% - 380px);
      display: inline-flex;
      flex-wrap: wrap;

      .img {
        flex: 0 0 65px;
        max-width: 65px;
        border: 1px solid rgba(226, 228, 232, 0.4);
        box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);
        padding: 5px;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        img {
          max-width: 45px;
        }

        .view-txt {
          font-size: 8px;
          width: 100%;
          display: inline-block;
          text-align: center;
          text-transform: uppercase;
          color: #42a2e0;
        }
      }

      .pro-dm {
        flex: 0 0 calc(100% - 65px);
        max-width: calc(100% - 65px);
        padding-left: 10px;

        h6 {
          font-size: 16px;
          color: #33334f;
          font-weight: 500;
          margin: 0;
          padding: 0;

          .del {
            color: #f0441e;
            font-size: 12px;
            margin-left: 6px;
          }
        }

        span {
          font-weight: 400;
          font-size: 13px;
          color: $secondary-color;
          opacity: 0.6;
        }
      }
    }

    .product-qty {
      flex: 0 0 120px;
      max-width: 120px;
      display: inline-flex;
      align-items: center;

      label {
        margin: 0 10px 0 0;
        padding: 0;
        font-size: 12px;
        font-weight: 500;
        color: $m-gray;
      }

      .form-control {
        width: 65px;
        font-size: 13px;
        color: $black-color;
        opacity: 0.7;
      }
    }

    .pro-price {
      flex: 0 0 250px;
      max-width: 250px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;

      .bl-price {
        font-weight: 400;
        font-size: 12px;
        opacity: 0.7;
        color: $m-gray;
        margin-right: 18px;
        color: #0f71b2;
        text-decoration: underline;
        cursor: pointer;
      }

      span {
        font-weight: 700;
        font-size: 18px;
        color: $m-gray;
        margin-right: 15px;
      }

      .dot-btn {
        padding: 0;
        background: none;
        border: none;
      }
    }
  }

  .error-bg {
    background: #FFCCCB;
  }

  .required {
    padding-left: 2px;
    color: red;
    font-weight: 700;
  }

  .form-box {
    margin-top: 15px;
    background: #f8f9fa;
    padding: 15px 16px;
    border-radius: 3px;

    .form-left {
      .frm-label {
        font-weight: 400;
        font-size: 13px;
        margin: 0 0 5px 0;
        padding: 0;
        color: $secondary-color;
        opacity: 0.7;
      }

      .form-select,
      .form-control {
        border: 1px solid #eaedf0;
        border-radius: 3px;
        font-size: 14px;
        color: $secondary-color;
      }

      textarea {
        resize: none;
      }

      .apply-check {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
          margin: 0;
          padding: 4px 0 0 5px;
          font-weight: 400;
          font-size: 14px;
          color: $secondary-color;
        }
      }

      .add-attach-file {
        .file-button {
          span {
            font-size: 13px;
            font-weight: 500;
            color: $primary-color;
            cursor: pointer;
          }
        }

        .attach-in {
          display: flex;
          flex-wrap: wrap;
          margin-left: -5px;
          margin-right: -5px;
          margin-top: 6px;

          .add-in {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding-right: 5px;
            padding-left: 5px;
          }

          .at-box {
            max-width: 100%;
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 8px;
            background: $white-color;
            border: 1px solid #dee2e6;
            border-radius: 4px;
            padding: 8px 8px;
            position: relative;
            color: $secondary-color;

            .close {
              position: absolute;
              right: 8px;
              top: 50%;
              transform: translateY(-50%);
              width: 8px;
              height: 8px;

              img {
                vertical-align: top;
              }
            }
          }
        }
      }
    }
  }
}

// step two css close

// address pop css

.address-pop {
  .modal-dialog {
    max-width: 840px;

    .modal-content {
      .modal-header {
        padding: 15px 30px;
      }

      .modal-title {
        font-weight: 500;
        font-size: 22px;
        color: $secondary-color;
      }

      .modal-body {
        padding: 0;

        .address-sl {
          max-height: 460px;
          padding: 30px 30px;
          overflow-y: auto;

          h5 {
            font-weight: 400;
            font-size: 18px;
            margin: 0 0 15px 0;
            padding: 0;
            color: $secondary-color;
          }
        }

        .ad-box {
          padding: 10px 15px;
        }

        .address-pop-form {
          .form-control {
            @include text-bar;
          }

          .form-select {
            @include text-bar;
          }
        }
      }

      .modal-footer {
        justify-content: space-between;
        padding: 14px 30px;

        .foot-left {
          a {
            font-weight: 500;
            font-size: 13px;
            color: $primary-color;
          }
        }

        .foot-right {
          display: inline-flex;

          .submit-btn {
            @include blue-btn;
            width: 115px;
            margin-left: 10px;
          }

          .close-btn {
            @include white-btn;
            width: auto;
          }
        }
      }
    }
  }
}

// address pop css close

// Payment css

.payment-option {
  .pay-heading {
    label {
      font-weight: 500;
      font-size: 18px;
      color: $secondary-color !important;
    }
  }

  .card-list {
    padding-left: 30px;
    margin-top: 20px;
  }
}

.card-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: $stripe-color;
  border-radius: 3px;
  padding: 20px 20px;
  margin-bottom: 15px;

  .add-pay {
    font-weight: 500;
    font-size: 18px;
    color: $primary-color;
  }

  .card-fields {
    flex: 0 0 calc(100% - 250px);
    max-width: calc(100% - 250px);
    display: inline-flex;
    flex-wrap: wrap;

    label {
      font-weight: 400;
      font-size: 13px;
      margin: 0;
      padding: 0;
      color: $secondary-color;
      opacity: 0.7;
    }

    .form-control {
      @include text-bar;
    }

    .Box-one {
      flex: 0 0 190px;
      max-width: 190px;
    }

    .Box-two {
      flex: 0 0 80px;
      max-width: 80px;
      margin: 0 15px;
    }

    .Box-three {
      flex: 0 0 120px;
      max-width: 120px;
    }
  }

  .card-options {
    flex: 0 0 250px;
    max-width: 250px;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;

    ul {
      display: inline-flex;
      margin: 0 20px 0 0;
      padding: 0;
    }

    li {
      list-style: none;
      position: relative;
      padding: 0 10px;

      &:before {
        content: "";
        width: 1px;
        height: 12px;
        background: #2B96DC;
        position: absolute;
        top: 8px;
        right: 0;
      }

      &:last-child {
        &::before {
          content: unset;
        }
      }
    }

    a {
      font-weight: 400;
      font-size: 13px;
      color: $primary-color;
    }

    .select-card-bt {
      @include blue-btn;
      width: 120px;
    }
  }
}

.later-box {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 30px 30px 30px 40px;
  margin-top: 20px;

  .rd-box {
    label {
      font-weight: 500;
      font-size: 18px;
      color: $secondary-color !important;
    }
  }
}

.pay-later-doc {
  padding: 0 0 0 30px;
  margin-top: 20px;

  .pay-later-doc {
    padding: 0 0 0 30px;
    margin-top: 20px;
  }

  .pl-form {
    background: #f8f9fa;
    border-radius: 3px;
    padding: 20px 20px;

    label {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 13px;
      color: $secondary-color;
      opacity: 0.7;
    }

    .ul-files {
      display: flex;
      flex-wrap: wrap;

      .file-button {
        label {
          border: 1px dashed rgba(109, 109, 167, 0.3);
          border-radius: 3px;
          width: 95px;
          font-weight: 400;
          font-size: 13px;
          cursor: pointer;
          padding: 8px 10px;
          text-align: center;
        }
      }

      .doc-name {
        flex: 0 0 140px;
        max-width: 140px;
        background: #fff;
        font-weight: 400;
        font-size: 13px;
        padding: 8px 25px 8px 10px;
        margin-left: 10px;
        position: relative;
        color: $secondary-color;
        opacity: 0.7;
        border: 1px solid #dee2e6;
        border-radius: 4px;

        a {
          position: absolute;
          right: 10px;
          width: 8px;
          height: 8px;
          top: 50%;
          transform: translateY(-50%);

          img {
            vertical-align: top;
          }
        }
      }
    }
  }

  .apply-check {
    display: flex;
    margin-top: 20px;
    padding: 0;
    align-items: center;

    p {
      margin: 0;
      padding: 0 0 0 8px;
      position: relative;
      top: 0;
      font-weight: 400;
      font-size: 13px;
      color: $secondary-color;
      opacity: 0.7;
    }
  }

  .later-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .place-btn {
      @include blue-btn;
      width: 120px;
    }

    .back-btn {
      @include white-btn;
      font-weight: 400;
      font-size: 14px;
      background: transparent;
      margin-right: 10px;
      text-align: center;
      width: 100px;
    }
  }
}

// Payment css close

// My order css

.order-main {
  padding-top: 0;
  position: relative;
}

.tabs-bar {
  .filter-container {
    display: flex;

    .order-length {
      padding-top: 9px;
      margin-right: 7px;
    }
  }

  .nav-tabs {
    border-bottom: none;
    background: #fff;

    &:before {
      content: "";
      width: 100%;
      height: 27px;
      background: #fff;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }

  .nav-item {
    margin-right: 20px;

    .nav-link {
      padding: 0;
      font-weight: 400;
      font-size: 14px;
      color: $secondary-color;
      border-radius: 0;
      padding-bottom: 2px;
      border-bottom: 3px solid transparent;

      &:hover {
        border-color: transparent;
      }
    }

    .active {
      border-bottom-color: $primary-color;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }
}

.result-bar {
  max-width: 290px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  h6 {
    font-weight: 500;
    font-size: 13px;
    flex: 0 0 120px;
    margin: 0;
    padding: 0;
    color: $secondary-color;
  }

  .form-select {
    flex: 0 0 160px;
    max-width: 160px;
    font-weight: 400;
    font-size: 14px;
    color: $secondary-color;
    border-radius: 3px;
    border: 1px solid #eaedf0;
  }
}

.tab-details {
  padding-top: 20px;
}

.order-box-holder {
  margin-top: 25px;

  .box-in {
    border-radius: 10px;
    padding: 0;
    align-items: unset;
    margin-bottom: 25px;

    .or-status {
      flex: 0 0 210px;
      max-width: 210px;
      border-right: 1px solid #ededed;
      padding: 20px 15px 20px 20px;

      ul {
        margin: 0;
        padding: 0 0 0 20px;
      }

      li {
        list-style: none;
        position: relative;
        padding-bottom: 20px;

        &:before {
          content: "";
          width: 1.5px;
          height: 100%;
          background: $border-grey;
          position: absolute;
          left: -18px;
          top: 2px;
        }

        &:last-child {
          padding-bottom: 0;

          &:before {
            content: unset;
          }
        }
      }

      .status-dot {
        width: 16px;
        height: 16px;
        display: inline-block;
        background: #fff;
        border: 2px solid #bdbdbd;
        border-radius: 50%;
        position: absolute;
        left: -25px;
        top: 2px;
      }

      h6 {
        font-weight: 400;
        font-size: 13px;
        margin: 0;
        padding: 0;
        color: $secondary-color;
      }

      .date-bx {
        font-weight: 400;
        font-size: 13px;
        margin: 0;
        padding: 0;
        color: rgba($secondary-color, 60%);
      }
    }

    .or-details {
      flex: 0 0 calc(100% - 210px);
      max-width: calc(100% - 210px);

      .or-bar {
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 15px 20px;
        border-bottom: 1px solid #ededed;
        align-items: center;
        justify-content: space-between;

        .or-left {
          display: inline-flex;

          .dropdown-toggle {
            background: none;
            border: none;
            padding: 0;
            color: $primary-color;
            font-size: 13px;
          }

          .t-heading {
            font-weight: 500;
            font-size: 13px;
            color: rgba($secondary-color, 60%);
          }

          span {
            font-weight: 500;
            font-size: 13px;
            color: rgba($secondary-color, 60%);
          }
        }

        .or-right {
          .share-btn {
            @include border-btn;
            margin-right: 10px;
            width: auto !important;
            cursor: pointer;

            .new-chat {
              font-weight: 700;
              font-size: 10px;
              color: #fff;
              background: #e1340d;
              padding: 1px 3px;
              border-radius: 2px;
            }
          }

          .or-btn {
            @include blue-btn;
            display: inline-block;
            text-align: center;
            padding: 8px 20px;
            box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);
            width: 150px;
            cursor: pointer;
          }
        }
      }

      .processing-box {
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .pro-left {
          flex: 0 0 50%;
          max-width: 50%;
          padding-right: 20px;

          h5 {
            font-weight: 500;
            font-size: 18px;
            margin: 0;
            padding: 0;
            color: $secondary-color;

            .quote-img {
              width: 32px;
              height: 32px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              border: 1px solid #333;
              position: relative;
              top: 4px;
              margin-right: 4px;

              img {
                position: relative;
                top: 0px;
              }
            }
          }

          h6 {
            font-weight: 400;
            font-size: 13px;
            margin: 0;
            padding: 0;
            color: rgba($secondary-color, 60%);
          }

          .ship-part {
            display: inline-flex;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 15px;

            .in-box {
              flex: 0 0 200px;
              max-width: 200px;
              border: 1px solid #dee2e6;
              border-radius: 3px;
              padding: 15px;
              display: inline-flex;
              flex-wrap: wrap;
              margin-right: 10px;
              word-break: break-all;

              .img {
                flex: 0 0 30px;
                max-width: 30px;
                height: 30px;
                background: #eff1f3;
              }

              .text {
                flex: 0 0 calc(100% - 30px);
                max-width: calc(100% - 30px);
                padding-left: 10px;


                h6 {
                  font-weight: 500 !important;
                  color: #333 !important;
                }

                p {
                  font-weight: 400;
                  font-size: 13px;
                  margin: 0;
                  padding: 0;
                  color: rgba($secondary-color, 70%);
                }

                .process {
                  color: rgba(51, 51, 51, 0.6) !important;
                }
              }
            }


            .more-box {
              background-color: red;
            }

            .more-parts {
              border: 1px solid #dee2e6;
              padding: 15px;
              flex: 0 0 160px;
              max-width: 160px;
              margin-left: 15px;
              border-radius: 3px;
              text-align: center;

              p {
                margin: 0;
                padding: 0;
                font-size: 13px;
                font-weight: 500;
                color: rgba($secondary-color, 60%);
              }

              a {
                font-weight: 400;
                font-size: 13px;
                color: $primary-color;
                text-decoration: none;
              }
            }
          }
        }

        .pro-right {
          flex: 0 0 150px;
          max-width: 150px;

          .share-btn {
            @include border-btn;
            margin-right: 10px;
            // width: auto !important;
            cursor: pointer;
            margin-top: 10px;

            .new-chat {
              font-weight: 700;
              font-size: 10px;
              color: #fff;
              background: #e1340d;
              padding: 1px 3px;
              border-radius: 2px;
            }
          }

          .or-btn {
            @include white-btn;
            width: 100%;
            display: inline-block;
            text-align: center;
            border: 1px solid rgba(226, 228, 232, 0.4);
            box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);
            color: $primary-color;
            opacity: 1;
            margin-bottom: 15px;
            cursor: pointer;
          }
        }

        .price-box {
          flex: 0 0 50%;
          max-width: 50%;

          .box-in {
            margin-bottom: 0;
            padding: 10px 5px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

// My orders css close

// order details css

.or-details-row {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-evenly;
  padding-bottom: 6px;
  width: 100%;

  .right-btns {
    text-align: right;
    // margin-top: -70px;
  }

  // border-bottom: 1px solid $border-color;
  .req-box {
    flex: 0 0 30%;
    max-width: 30%;

    .requirement-box {
      width: 90vw !important;
    }

    h5 {
      margin: 0 0 5px 0;
      opacity: 1;
    }

    p {
      font-weight: 400;
      font-size: 13px;
      margin: 0;
      padding: 0;
      color: rgba($secondary-color, 60%);
    }
  }

  .qr-code-div {
    text-align: left;

    .qr-file-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 30px;

      p {
        font-size: 14px;
      }

      .upload-po-text {
        display: inline;
        color: gray;
        width: 250px;
        justify-content: center;
        align-items: center;
      }

      .input-qr-image {
        width: 0px;
        visibility: hidden;
      }

      .qr-file-input__label {
        margin-left: 20px;
        width: 100px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 600;
        color: $primary-color;
        padding: 5px 12px;
        background-color: $white-color;
        box-shadow: 3px 3px 3px 3px rgb(241, 241, 241);
      }
    }
  }


  .hd-buttons {
    flex: 1;
    max-width: 100%;
    display: inline-flex;
    justify-content: flex-end;

    .ship-btn {
      @include border-btn;
      width: 150px;
      text-align: center;
      margin-left: 15px;
      padding: 8px 20px;
      box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.07);
    }
  }

  .cn-machine-bar {
    margin-top: 25px;

    h5 {
      font-weight: 400;
      font-size: 16px;
      margin: 0;
      padding: 0;
      color: #333;
    }
  }

  .pro-bar {
    display: flex;
    flex-wrap: wrap;

    .pro-image {
      flex: 0 0 100px;
      max-width: 100px;
      background: #ddd;
      height: 100px;
    }

    .pro-text {
      flex: 0 0 100%;
      max-width: 100%;

      .pro-bar-one {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .pro-qt {
          display: inline-flex;

          .box-one {
            h6 {
              margin: 0;
              padding: 0;
              font-weight: 500;
              font-size: 16px;
              color: #333;
            }

            p {
              margin: 0;
              padding: 0;
              font-weight: 400;
              font-size: 13px;
              color: #333333c4;
            }
          }

          .qty-box {
            padding-left: 20px;

            label {
              margin: 0 10px 0 0;
              padding: 0;
              font-weight: 500;
              font-size: 12px;
              color: rgba($secondary-color, 70%);
            }

            .text-fld {
              width: 50px;
              min-height: 32px;
              border: 1px solid $border-color;
              border-radius: 3px;
              background: #f8f9fa;
              padding: 3px 8px;
              font-size: 16px;

              &:focus {
                outline: none;
              }
            }
          }
        }

        .pro-price {
          color: #555;
          font-weight: 700;
          font-size: 18px;
        }
      }

      .pro-bar-two {
        display: flex;
        flex-wrap: wrap;
        // background: #f8f9fa;
        padding: 10px;
        margin-top: 10px;
        margin-left: -7px;
        margin-right: -7px;
        width: 100%;

        .col {
          padding-right: 7px;
          padding-left: 7px;

          .consignmentDiv {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .consignmentCard {
              padding: 5px;
              text-align: center;
              margin: 5px;
              box-shadow: 5px 5px 5px rgb(232, 232, 232);
              transition: 0.3s;
            }

            .consignmentCard:hover {
              transform: scale(1.05);
            }

            .downloadables {
              font-size: 10px;
            }
          }

          p {
            margin: 0;
            padding: 0;
            font-size: 13px;
            color: rgba($secondary-color, 70%);

            a {
              color: $primary-color;
            }
          }

          table {
            margin-top: 30px;
            min-width: 350px;
            text-align: left;
            border: gray;

            tr {

              // background-color: red;
              td {
                padding: 20px 20px 20px 0px;
              }
            }
          }
        }
      }
    }
  }
}

.del-box {
  background: #f4f6f8;
  border: 1px solid #dee2e6;
  position: relative;
  top: -1px;
  border-radius: 3px;
  padding: 20px 20px;

  h6 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    color: $secondary-color;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    margin: 0;
    padding: 0;
    color: rgba($secondary-color, 60%);
  }
}

// order details css close

.error {
  color: red;
  font-size: 12px;
  position: relative;
  bottom: 4px;
}

.dropbx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dropicon-bx {
  width: 26px;
  height: 26px;
  padding: 0px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #adadad;

  &:after {
    display: none;
  }

  .arrow-icon {
    svg {
      fill: #adadad;
      width: 12px;
    }
  }

  &:hover {
    background-color: #fff;
    border-color: #adadad;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
    background-color: #fff !important;
    border-color: #adadad !important;
  }
}

.processing {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 20px;
}

.processing1 {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  flex-direction: column;
  gap: 5px;
  padding: 0 20px;
}


.try-load {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;

  img {
    max-width: 46px;
  }
}

.pvtop-bar {
  background: $primary-color;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  .back-btn {
    @include white-btn;
    width: auto;
    opacity: 1;
    height: 40px;
    padding: 8px 20px;
  }
}

.success {
  background-color: #00c389;
}

.privacy-box {
  max-width: 960px;
  margin: 40px auto 30px;
  padding: 40px 50px;
  background: #fff;
  border-radius: 10px;

  h2 {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 24px;
    color: $secondary-color;
  }

  p {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    color: rgba($secondary-color, 70%);
  }

  h5 {
    font-size: 18px;
    margin: 25px 0 10px 0;
    padding: 0;
    color: $secondary-color;
  }

  .u-line {
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
  }

  .ui-text {
    text-decoration: underline;
    font-style: italic;
  }

  ul {
    margin: 0 0 30px 0;
    padding: 0 0 0 30px;
  }

  li {
    font-size: 14px;
    margin: 0 0 15px 0;
    color: rgba($secondary-color, 70%);
    list-style: outside;

    span {
      padding-left: 10px;
    }

    .count {
      font-weight: 700;
      color: $secondary-color;
      flex: 0 0 40px;
      max-width: 40px;
    }

    .desc {
      font-weight: 500;
      color: rgba($secondary-color, 70%);
      flex: 0 0 calc(100% - 40px);
      max-width: calc(100% - 40px);
      padding-left: 10px;

      p {
        background-color: yellow;
      }

      span {
        text-decoration: underline;
      }
    }

    &:last-child {
      margin: 0;
    }

    strong {
      color: $secondary-color;
    }
  }
}

// privacy css close

.terms-section {
  max-width: 960px;
  margin: 40px auto 30px;
  padding: 40px 50px;
  background: #fff;
  border-radius: 10px;

  h2 {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 24px;
    color: $secondary-color;
  }

  p {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    color: rgba($secondary-color, 70%);
  }

  h5 {
    font-size: 18px;
    margin: 25px 0 10px 0;
    padding: 0;
    color: $secondary-color;
  }

  .u-line {
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
  }

  .ui-text {
    text-decoration: underline;
    font-style: italic;
  }

  ul {
    margin: 0 0 30px 0;
    padding: 0 0 0 30px;
  }

  li {
    font-size: 14px;
    margin: 0 0 15px 0;
    color: rgba($secondary-color, 70%);
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    span {
      padding-left: 10px;
    }

    .count {
      font-weight: 700;
      color: $secondary-color;
      flex: 0 0 40px;
      max-width: 40px;
    }

    .desc {
      font-weight: 500;
      color: rgba($secondary-color, 70%);
      flex: 0 0 calc(100% - 40px);
      max-width: calc(100% - 40px);
      padding-left: 10px;

      p {
        background-color: unset;
      }

      span {
        text-decoration: underline;
      }
    }

    &:last-child {
      margin: 0;
    }

    strong {
      color: $secondary-color;
    }
  }
}

// terms css close

.lg-popup-bx {
  .modal-content {
    border: none;
    border-radius: 10px;
    box-shadow: 0 15px 40px rgb(0, 0, 0, 0.06);
    overflow: hidden;
  }

  .verfiy-img {
    text-align: center;
    margin-top: 30px;

    img {
      max-width: 120px;
    }
  }

  h4 {
    font-size: 24px;
    text-align: center;
    margin: 25px 0 15px 0;
    padding: 0 20px;
    color: $secondary-color;
  }

  .pop-login {
    @include blue-btn;
    display: inline-block;
    text-align: center;
    width: 100%;
    max-width: 110px;
    margin: 0 auto 30px;
  }

  .signup-bx {
    text-align: center;
    padding: 0 20px 40px 20px;

    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }
}

.no-content-box {
  min-height: 240px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba($secondary-color, 60%);
  font-size: 16px;

  a {
    color: $white-color;
    margin-left: 5px;
    background: $primary-color;
    padding: 2px 10px;
    border-radius: 5px;
  }
}

.pop-logo {
  background: $primary-color url(images/login-bg.png) no-repeat;
  padding: 20px 20px;
  text-align: center;
}

.login-bt {
  text-align: center;
  margin-bottom: 30px;

  a {
    @include blue-btn;
    display: inline-block;
    width: 120px;
  }
}

.file-name {
  justify-content: flex-start !important;

  .heading {
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  .name {
    font-size: 14px !important;
    padding-left: 6px;
    font-weight: 500 !important;
    color: rgba($secondary-color, 70%) !important;
  }
}

.req-quote-btn {
  @include blue-btn;
  display: inline-block;
  width: auto;
  margin-top: 20px;
}

.address-bar {
  .billing-form {
    .address {
      .singleAddress {
        width: 520px;
        height: 80px;
        background: #FFFFFF;
        border: 1px solid #DEE2E6;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding: 10px;


        .address {
          .name {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #333333;
          }

          .add {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #333333;
            opacity: 0.6;
            // width: 100%;
            // border: 1px solid;
          }
        }

        .buttons {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #2B96DC;

          .edit,
          .delete {
            margin-right: 5px;
            margin-left: 5px;
            font-family: "Red Hat Display", serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            text-align: right;
            text-decoration-line: underline;
            color: #2B96DC;
            cursor: pointer;
          }
        }
      }

      .allAddress {
        width: 520px;
        // height: 80px;
        background: #FFFFFF;
        border: 1px solid #DEE2E6;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        // padding-left: 10px;
        padding: 10px;

        .link {
          font-family: 'Red Hat Display';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          display: flex;
          align-items: center;
          text-decoration-line: underline;
          color: #2B96DC;
          cursor: pointer;
        }

        .addNew {
          font-family: 'Red Hat Display';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #2B96DC;
          cursor: pointer;
        }
      }
    }

    textarea {
      resize: none;
    }
  }
}

.oqr-btn {
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

.sq-btn {
  max-width: 100% !important;
  flex: 0 0 100% !important;
  display: inline-block;
}

.file-container {
  max-width: 480px;
  margin-left: auto;

  .attach-file-bx {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;

    .attach-name {
      flex: 0 0 calc(100% - 100px);
      max-width: calc(100% - 100px);
      @include text-bar;
      border-radius: 3px 0 0 3px;
      border-color: #dee2e6;
      border-right: none;

      &:focus-visible {
        box-shadow: unset;
        outline: none;
      }
    }

    .fl-btn {
      flex: 0 0 100px;
      max-width: 100px;

      .file-input__input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      .file-input__label {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        border-radius: 0 3px 3px 0;
        font-size: 14px;
        font-weight: 600;
        color: $primary-color;
        font-size: 14px;
        padding: 10px 12px;
        width: 100%;
        margin-top: 0;
        background-color: $white-color;
      }
    }
  }

  .sub-btn {
    text-align: right;

    .update-button {
      padding: 10px 30px;
    }
  }

  .message-box {
    margin-top: 30px;
    text-align: right;

    .mesg-box {
      @include text-bar;
      width: 100%;
      resize: none;
      height: 120px;

      &:focus-visible {
        box-shadow: unset;
        outline: none;
      }
    }

    .update-button {
      padding: 10px 30px;
      margin-top: 10px;
    }
  }

  .message-btns {
    margin-top: 30px;
    padding: 40px 20px;
    text-align: center;
    background: #ececec;

    button.update-button {
      padding: 10px 30px;
    }
  }
}

.search-bar {
  margin-bottom: 40px;

  .row {
    justify-content: space-between;
    align-items: center;
  }

  .search-block {
    max-width: 200px;

    .sr-label {
      font-size: 14px;
      margin: 0;
      padding: 0;
      color: rgba($secondary-color, 70%);
      width: 100%;
      text-align: left;
    }

    .direct-search-drop-down-div {
      position: absolute;
      background-color: white;
      width: calc(50% - 16px);
      border: 1px solid black;
      margin-top: 0px;
      border-radius: 3px;
      max-height: 300px;
      overflow: auto;

      .search-table {
        width: 100%;

        th {
          background-color: #2B96DC;
          color: white;
        }

        td {
          border: 1px solid lightgray;
        }
      }


      .direct-search-drop-down {
        width: 100%;

        p {
          margin-bottom: 0px;
          padding-left: 5px;
          font-size: 15px;
        }

        cursor: pointer;

        a {
          display: block;
          color: #000;
          width: 100%;
        }
      }

      .direct-search-drop-down:hover {
        background-color: #156ea9;
        color: white;

        a {
          color: #fff;
        }
      }
    }
  }

  .search-btn {
    text-align: left;

    .button-search {
      padding: 8px 16px;
      background: $primary-color;
      color: $white-color;
      font-size: 14px;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
}

.button-edit {
  padding: 6px 10px;
  background: rgb(218, 195, 94);
  color: $white-color;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  margin: 5px;
  width: 100%;
  min-width: 120px;
  max-width: 150px;
}

.button-delete {
  padding: 6px 10px;
  background: rgb(196, 0, 0);
  color: $white-color;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  margin: 5px;
  width: 100%;
  min-width: 120px;
  max-width: 150px;
}

.button-add {
  padding: 8px 16px;
  background: rgb(37, 104, 167);
  color: $white-color;
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  margin: 5px;
}

.resend-otp-container {
  display: flex;
  justify-content: flex-end;
}

.resend-otp {
  color: #0a58ca;
  text-decoration: underline;
  // margin-top: 10px;
  cursor: pointer;
}

.signin-week {
  padding-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  input[type="checkbox"] {
    display: block;
    transform: scale(1.2);
  }
}

.signin-text {
  padding-left: 10px;
}

// media css start

@media (min-width: 576px) {
  .modal-dialog {
    max-width: unset;
    width: 90%;
    margin: 10px auto;

  }
}

@media (max-width: 1400px) {
  .sm-container {
    padding: 0 20px;
  }

  .login-main {
    height: 100vh;
    background-repeat: repeat-y;
  }

  .intro-bar {
    padding: 20px 150px;
  }
}

@media (max-width: 1200px) {
  .login-main .login-in .log-right {
    flex: 0 0 480px;
    max-width: 480px;
    padding: 40px 40px;
  }

  .top-bar .menu-holder .main-menu li {
    margin-right: 25px;
  }

  .top-bar .menu-holder .right-menu li {
    margin-left: 35px;
  }

  .btm-buttons .br-btn {
    padding: 8px 5px;
    font-size: 13px;
  }

  .order-box-holder .box-in .or-details .processing-box .pro-left {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .order-box-holder .box-in .or-details .processing-box .price-box {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 15px;
  }

  .intro-bar {
    padding: 20px 20px;
  }
}

@media (max-width: 992px) {
  .login-main .login-in .log-right {
    flex: 0 0 420px;
    max-width: 420px;
    padding: 40px 25px;
  }

  .login-main .login-in .log-right .signin-holder .signup-bx {
    margin: 30px 0 20px;
  }

  .login-main .login-in .log-left h2 {
    font-size: 28px;
  }

  .dashboard-holder .dashboard-in .dash-left .ds-heading {
    font-size: 24px;
    margin: 0 0 20px 0;
    line-height: 36px;
  }

  .dashboard-holder .dashboard-in .dash-left .ds-btn {
    margin: 20px 0 40px 0;
  }

  .dashboard-holder .dashboard-in {
    padding: 20px 30px 20px 30px;
  }

  .top-bar {
    padding: 18px 20px;
  }

  .dashboard-holder {
    padding: 30px 0;
  }

  // .price-box {
  //   margin-top: 15px;
  // }

  .quote-right {
    margin-top: 20px;
  }

  .order-box-holder .box-in .or-status {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-box-holder .box-in .or-status ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .order-box-holder .box-in .or-status li {
    flex: 1;
    max-width: 100%;
  }

  .order-box-holder .box-in .or-details {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-box-holder .box-in .or-status .status-dot {
    position: relative;
    left: 0;
    top: 0;
  }

  .order-box-holder .box-in .or-status li::before {
    width: 100%;
    height: 1px;
    left: 0;
    top: 8px;
  }

  .order-box-holder .box-in .or-status h6 {
    padding: 0 10px 0 0;
  }

  .dashboard-holder.order-main {
    padding-top: 0;
  }

  .pay-box-in {
    padding: 30px 20px 30px 20px;
  }

  .card-box .card-fields {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .card-box .card-options {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: flex-start;
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .order-pay-box .shippin-details .boxx-in {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .profile-main .profile-info .info-in .profile-details {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    margin-top: 15px;
  }

  .profile-main .profile-info .info-in .profile-details .password-left {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .profile-main .profile-info .info-in .profile-details .password-ryt {
    flex: 0 0 100%;
    max-width: 100%;
    flex-direction: row-reverse;
    margin-top: 20px;
  }

  .profile-main .address-box .add-new-bx a {
    font-size: 15px;
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .profile-main .address-box .add-box p {
    max-width: 100%;
  }

  .profile-main .profile-info .info-in .profile-details .form-control {
    margin-bottom: 10px;
  }

  .profile-main .address-box .add-box {
    margin-top: 10px;
  }
}

.trk-order {
  background: #FFFFFF;
  width: 180px;
  color: #2B96DC;
  border: 1px solid rgba(226, 228, 232, 0.4);
  box-shadow: 0px 8px 16px rgb(30 52 92 / 10%);
  border-radius: 4px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  cursor: pointer;
}

@media (max-width: 768px) {
  .login-main .login-in .log-right {
    flex: 100% 100% 100%;
    max-width: 380px;
    padding: 40px 25px;
  }

  .login-main .login-in .log-right::before {
    content: unset;
  }

  .login-btn {
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
  }
}

@media (max-width: 767px) {
  .login-main .login-in .log-left {
    flex: 100% 100% 100%;
    padding-right: 0;
  }

  .login-main .login-in .log-right {
    flex: 100% 100% 100%;
    max-width: 100%;
    padding: 20px 25px;
  }

  .login-main .login-in .log-left h2 {
    max-width: 100%;
  }

  .login-main .login-in .log-left .logo-tp {
    margin-bottom: 30px;
  }

  .login-main .login-in .log-left p {
    font-size: 16px;
    margin: 10px 0 0 0;
    max-width: 100%;
  }

  .login-main .login-in .log-left .log-points li {
    margin: 0 0 10px 0;
  }

  .login-main .login-in .log-left .log-points {
    margin: 10px 0 20px 0;
  }

  .login-main .right-top-shape {
    max-width: 230px;
  }

  .menu-ico {
    display: none;
  }

  .top-bar .menu-holder {
    justify-content: flex-end;
  }

  .dashboard-holder .dashboard-in {
    padding: 20px 20px;
  }

  .dashboard-holder .dashboard-in .dash-left .ds-btn {
    margin: 20px 0 20px 0;
  }

  .dashboard-holder .dashboard-in .dash-left .ds-heading {
    font-size: 18px;
    margin: 0 0 20px 0;
    line-height: 30px;
  }

  .intro-bar .int-left {
    flex: 100% 100% 100%;
    max-width: 100%;
  }

  .intro-bar h3 {
    font-size: 18px;
  }

  .intro-bar .int-right {
    flex: 100% 100% 100%;
    max-width: 100%;
    margin-top: 15px;
    align-items: flex-start;
  }

  .intro-bar .search-bar {
    max-width: 240px;
    display: inline-flex;
    width: 100%;
    border: 1px solid #eaedf0;
    border-radius: 3px;
    align-items: center;
    padding: 10px 10px;

    .form-control,
    .form-select {
      font-weight: 400;
      font-size: 14px;
      border: none;
      color: $secondary-color;
      opacity: 0.7;
      padding: 0 10px 0 0;
    }
  }

  .process-thumbs .row .col {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .process-thumbs .pr-radio {
    margin-bottom: 15px;
  }

  .common-bx {
    padding: 30px 20px 30px 20px;
    position: relative;
  }

  .process-bar-bx .process-ico,
  .upload-req .process-ico,
  .order-pay-box .order-ico {
    left: -5px;
  }

  // .process-bar-bx::before,
  // .upload-req::before {
  //   left: 10px;
  // }

  .upload-req .upload-box p {
    text-align: center;
  }

  .upload-req .upload-box {
    padding: 20px 20px;
  }

  .upload-req .sample-box .text {
    font-size: 13px;
  }

  .product-thumb .pro-bar .product-name {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .product-thumb .pro-bar .product-qty {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .product-thumb .pro-bar .pro-price {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .order-pay-box .order-deatils-bx .billing-box .shipping-info .apply-check {
    padding: 20px 15px;
  }

  .order-box-holder .box-in .or-details .or-bar .or-left {
    display: inline-flex;
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;
    background: #f4f8fb;
    padding: 10px;
    border-radius: 5px;
  }

  .or-right {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 10px;
  }

  .order-box-holder .box-in .or-details .processing-box .pro-left {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }

  .order-box-holder .box-in .or-details .processing-box .pro-right {
    flex: 0 0 100%;
    max-width: 100%;
    display: inline-flex;
    margin-top: 15px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .order-box-holder .box-in .or-details .processing-box .pro-right .or-btn {
    flex: 0 0 49%;
    max-width: 49%;
  }

  .later-box {
    padding: 30px 20px 30px 20px;
  }

  .or-details-row .req-box {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .or-details-row .cnc-box {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .order-pay-box .shippin-details .boxx-in {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 15px;
  }

  .or-details-row .hd-buttons {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .or-details-row .hd-buttons .ship-btn {
    margin-left: 0;
    flex: 0 0 31.33%;
    max-width: 31.33%;
    padding: 8px 10px;
    font-size: 14px;
  }

  .order-pay-box {
    padding-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .dashboard-holder .dashboard-in .dash-left {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .dashboard-holder .dashboard-in .dash-img {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .process-thumbs .row .col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .upload-req .sample-box .text {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }

  .upload-req .sample-box .upload-btn {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .product-thumb .pro-bar .product-qty {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .product-thumb .pro-bar .pro-price {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: flex-start;
  }

  .product-thumb .pro-bar .product-qty .form-control {
    width: 100%;
  }

  .price-tabs .box-pr {
    flex: 100% 100% 100%;
    width: 100%;
    margin-bottom: 15px;
  }

  .btm-buttons .br-btn {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 10px;
  }

  .product-thumb .form-box .form-left .add-attach-file .attach-in .add-in {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .product-thumb .pro-bar .pro-price .bl-price {
    flex: 0 0 calc(100% - 110px);
    max-width: calc(100% - 110px);
  }

  .product-thumb .pro-bar .pro-price span {
    flex: 0 0 70px;
    max-width: 70px;
  }

  .product-thumb .pro-bar .pro-price .dot-btn {
    flex: 0 0 10px;
    max-width: 10px;
  }

  .step-to-upload .up-text {
    justify-content: center;
  }

  .ad-box .rd-left {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 0;
    min-height: 25px;
  }

  .ad-box .ad-text {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }

  .ad-box .action-text {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  }

  .ad-box .action-text li:first-child {
    padding: 0 10px 0 0;
  }

  .order-pay-box .all-address {
    justify-content: center;
  }

  .order-pay-box .all-address a {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    margin: 5px 0;
  }

  .order-box-holder .box-in .or-details .processing-box .pro-left .ship-part {
    justify-content: space-between;
  }

  .order-box-holder .box-in .or-details .processing-box .pro-left .ship-part .in-box {
    flex: 0 0 49%;
    max-width: 49%;
  }

  .order-box-holder .box-in .or-details .processing-box .pro-left .ship-part .more-parts {
    flex: 0 0 49%;
    max-width: 49%;
    margin-left: 0;
  }

  .card-box .card-fields .Box-one {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .card-box .card-fields .Box-two {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 10px 0 0 0;
  }

  .card-box .card-fields .Box-three {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 10px 0 0 0;
  }

  .ul-files .file-button {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pay-later-doc .pl-form .ul-files .doc-name {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  .pay-later-doc .apply-check {
    margin-top: 0;
  }

  .pay-later-doc .later-btns {
    margin-top: 20px;
    flex-direction: column-reverse;
  }

  .card-box .card-options ul {
    margin: 0 10px 0 0;
  }

  .pay-later-doc .later-btns .back-btn {
    width: 100%;
    margin-right: 0;
  }

  .pay-later-doc .later-btns .place-btn {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .or-details-row .hd-buttons .ship-btn {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}

.ship {
  color: #858585 !important;
}

.shipping-details {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 480px) {
  .login-main .login-in {
    padding: 40px 0 40px 0;
  }

  .login-main .login-in .log-left h2 {
    font-size: 22px;
  }

  .login-main .login-in .log-left .log-points li .icon {
    flex: 0 0 20px;
    max-width: 20px;
    height: 20px;
  }

  .login-main .login-in .log-left .log-points li .icon img {
    width: 10px;
  }

  .login-main .login-in .log-left p {
    font-size: 14px;
    line-height: 22px;
  }

  .right-top-shape {
    display: none;
  }

  .login-main .login-in .log-right .signin-holder {
    margin: 20px 0 0 0;
  }

  .login-main .login-in .log-right .signin-holder h6 {
    margin-bottom: 20px;
  }

  .order-box-holder .box-in .or-status h6 {
    font-size: 12px;
  }

  .order-box-holder .box-in .or-status .date-bx {
    font-size: 12px;
  }

  .order-box-holder .box-in .or-details .processing-box .pro-left .ship-part .in-box {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-box-holder .box-in .or-details .processing-box .pro-left .ship-part .more-parts {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 10px;
  }

  .order-box-holder .box-in .or-details .processing-box .pro-right .or-btn {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-box-holder .box-in .or-details .or-bar .or-right .or-btn {
    width: 100%;
  }

  .card-box .card-options ul {
    margin: 0 10px 0 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .card-box .card-options li {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;
  }

  .card-box .card-options .select-card-bt {
    width: 100%;
    margin: 15px 0 0 0;
  }

  .card-box .add-pay {
    font-size: 15px;
  }

  .order-box-holder .box-in .or-details .processing-box .pro-right .share-btn {
    display: inline-block;
    text-align: center;
    margin-bottom: 10px;
  }

  .tabs-bar .nav-item {
    margin-right: 10px;
  }

  .tabs-bar .nav-item .nav-link {
    font-size: 13px;
  }

  .order-box-holder .box-in .or-details .or-bar .or-left .invoice {
    margin-right: 0 !important;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .order-box-holder .box-in .or-details .or-bar .or-left .total {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0 !important;
    margin-bottom: 15px;
  }

  .order-box-holder .box-in .or-details .or-bar .or-left .ship {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-pay-box .shippin-details .boxx-in {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .or-details-row .cnc-box {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 15px;
  }
}

@media (max-width: 375px) {
  .result-bar {
    max-width: 100%;
  }

  .result-bar h6 {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 10px 0;
  }

  .result-bar .form-select {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .intro-bar .int-right .chat-btn {
    width: 100%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Red Hat Display", serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

.sidebar-hidden {
  .logo-bx {
    cursor: pointer;
    border: 1px solid $primary-color;
    position: absolute;
    color: $primary-color;
    padding: 5px 10px;
    background-color: $white-color;
    margin: 5px;
    border-radius: 3px;

    img {
      height: 30px;
      width: 30px;
    }

    transition: 0.1s;
  }

  .logo-bx:hover {
    transform: translateX(0px);
    transform: scale(1.02);
  }
}

.admin-container {
  display: flex;
  width: 100%;
}

.sidebar-menu {
  overflow-y: auto;
  width: 260px;
  background: #242834;
  height: 100%;
  min-height: 100vh;
  // height: 100vh;
  // overflow-y: scroll;
  // position: fixed;
  float: left;
  padding: 0;
  cursor: pointer;


  .close-icon {
    float: right;
    margin: 10px;
    font-size: 15px;
    color: white;
    border: 1px solid white;
    background-color: #ff2600e5;
    cursor: pointer;
    padding: 0px 7px;
    border-radius: 3px;
  }

  .logo-bx {
    padding: 20px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
  }

  .menu-section {
    // padding:30px 10px;

    ul {
      margin: 0;
      padding: 0;
    }

    .menu {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      color: white;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        text-decoration: none;
      }

      a {
        color: #fff;
      }
    }

    .sub-menu {
      list-style: none;

      a {
        background: #0f71b2;
        padding: 12px 10px;
        padding-left: 30px;
        font-size: 16px;
        color: #fff;
        display: inline-block;
        width: 100%;

        // border-radius: 5px;
        &:hover {
          background: rgba(255, 255, 255, 0.1);
          text-decoration: none;
        }
      }
    }
  }
}

.right-container {
  padding: 30px 30px;
  width: 100%;
  margin: 0px auto;
  text-align: center;

  .update-button {
    background: #2B96DC;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    padding: 5px 10px;
    border: none;
  }

  .quotes-table {
    width: 100%;
    font-size: 0.9rem;

    .table {
      width: 100%;

      th {
        font-size: 14px;
        text-transform: uppercase;
        background: #f8f9fa;
        padding: 15px 10px;
        color: #555;
        border-bottom: none;
        white-space: nowrap;
        text-align: left;
      }

      td {
        font-size: 14px;
        text-align: left;
        font-weight: 400;
        color: #6e6e82;
        padding: 20px 10px;
        white-space: nowrap;
        max-width: 250px;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal;

        .button-search {
          padding: 8px 16px;
          background: $primary-color;
          color: $white-color;
          font-size: 14px;
          font-weight: 500;
          border: none;
          border-radius: 5px;
          margin-right: 10px;
        }

        .un-fld {
          width: 100px;
          border: 1px solid #ddd;
          font-size: 14px;
          padding: 5px 10px;
          color: #333;
          border-radius: 4px;
        }

        .up-button {
          background: #2B96DC;
          color: #fff;
          font-size: 14px;
          border-radius: 4px;
          padding: 5px 10px;
          border: none;
        }

        .view-link {
          cursor: pointer;
          font-size: 14px;
          padding: 4px 10px;
          border-radius: 4px;
          display: inline-block;
          background: #2B96DC;
          color: #fff;
          border: none;

          &:hover {
            background: #2387ca;
            text-decoration: none;
          }
        }

        .prd-thumb {
          width: 230px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .img {
            flex: 0 0 40px;
            max-width: 40px;
            overflow: hidden;
            height: 40px;
            background: #ddd;
            border-radius: 4px;
            border: 1px solid #ddd;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .thumnd-text {
            flex: 0 0 calc(100% - 40px);
            max-width: calc(100% - 40px);
            padding: 0 0 0 10px;

            h6 {
              margin: 0;
              padding: 0;
              font-size: 13px;
              color: #333;
            }

            .thumnd-text p {
              margin: 0;
              padding: 0;
              font-size: 13px;
              color: #e2dfdf;
            }
          }
        }

        .desc-part {
          width: 150px;
          display: inline-block;

          p {
            font-size: 14px;
            color: #757575;
            word-break: break-all;
            font-weight: 500;
            margin: 0;
            padding: 0 0 0 10px;
            position: relative;

            &::before {
              content: "";
              width: 5px;
              height: 5px;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 20px;
              background: #757575;
            }
          }
        }

        .Notes {
          width: 260px;
          font-size: 14px;
          color: #757575;
          display: inline-block;
          word-break: break-all;
          font-weight: 500;
        }
      }
    }

    .table> :not(:first-child) {
      border-top: none;
    }
  }
}

.center-heading {
  text-align: center;
}

.floatRight {
  float: right;
}

// media css close

.chatButton {
  background: #2B96DC;
  border-radius: 4px;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 15px;
  transition: 0.4s;
  text-decoration: none;
  border: 1px solid transparent;
  display: inline-block;
  text-align: center;
  padding: 8px 20px;
  box-shadow: 0px 8px 16px rgb(30 52 92 / 10%);
  width: 80px;
  cursor: pointer;
  margin-right: 10px;
}

.admin-panel-container {
  width: 100%;
  margin: 10px;
  max-height: 98vh;
  overflow: auto;

  label {
    min-width: 200px;
    margin: 5px 10px;
    font-weight: bolder;
  }

  input[type="text"],
  input[type="password"],
  input[type="number"] {
    width: 40%;
  }

  input[type="radio"] {
    margin-left: 40px;
  }

  select {
    width: 40%;
    padding: 2px;

    option {
      color: black;
    }
  }

  select:active {
    border: 1px solid black;
  }

  .button-search {
    padding: 8px 16px;
    background: $primary-color;
    color: $white-color;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
  }

  .supplierButton {
    background-color: $complementary-primary-color;
    border: 1px solid $complementary-primary-color;
  }

  .type-of-services-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-bottom: 10px;
    margin-left: 10px;
    padding: 3px;
    min-width: 600px;
  }
}

PhoneInput {
  width: 40%;
}



.admin-user-container {
  margin: 10px;

  .admin-user-header {
    border-bottom: 1px solid black;
    text-align: left;
    font-size: 2rem;
    padding: 5px 15px;
  }

  .admin-user-body {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .admin-status-div {
      text-align: left;

      * {
        margin: 0px 5px;
        font-size: 15px;
      }
    }

    .disabled-email {
      background-color: transparent;
      border: none;
    }

    input[type="text"],
    input[type="number"],
    input[type="password"],
    select {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #ccc;
      font-size: 15px;
      padding: 5px 0px;
      margin-top: 12px;

      &:focus {
        outline: none;
      }
    }

    select {
      // appearance: none;
      // -webkit-appearance: none;
      // -moz-appearance: none;
      padding-right: 25px;
      /* Space for dropdown arrow */
      position: relative;
    }

    option {
      font-size: 15px;
    }

    .admin-user-name,
    .admin-user-email {
      display: flex;
    }

    .admin-user-name>input,
    .admin-user-name>select,
    .admin-user-email>input,
    .admin-user-email>select,
    .admin-user-email>.admin-status-div,
    .admin-user-email>.admin-user-password {
      width: 100%;
      margin: 10px;
    }

    .admin-user-email>p {
      width: 100%;
      margin: 0px 10px;
      text-align: left;
      font-size: 10px;
    }
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

d-thumb {
  width: 230px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .img {
    flex: 0 0 40px;
    max-width: 40px;
    overflow: hidden;
    height: 40px;
    background: #ddd;
    border-radius: 4px;
    border: 1px solid #ddd;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .thumnd-text {
    flex: 0 0 calc(100% - 40px);
    max-width: calc(100% - 40px);
    padding: 0 0 0 10px;

    h6 {
      margin: 0;
      padding: 0;
      font-size: 13px;
      color: #333;
    }

    .thumnd-text p {
      margin: 0;
      padding: 0;
      font-size: 13px;
      color: #757575;
    }
  }
}

.desc-part {
  display: inline-block;

  p {
    font-size: 14px;
    color: #757575;
    word-break: break-all;
    font-weight: 500;
    margin: 0;
    padding: 0 0 0 10px;
    position: relative;

    // &::before {
    //   content: "";
    //   width: 5px;
    //   height: 5px;
    //   position: absolute;
    //   left: 0;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   border-radius: 20px;
    //   background: #757575;
    // }
  }
}

.Notes {
  width: 260px;
  font-size: 14px;
  color: #757575;
  display: inline-block;
  word-break: break-all;
  font-weight: 500;
}

.table> :not(:first-child) {
  border-top: none;
}

.center-heading {
  text-align: center;
}

.floatRight {
  float: right;
}

// media css close

.chatButton {
  background: #2B96DC;
  border-radius: 4px;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 15px;
  transition: 0.4s;
  text-decoration: none;
  border: 1px solid transparent;
  display: inline-block;
  text-align: center;
  padding: 8px 20px;
  box-shadow: 0px 8px 16px rgb(30 52 92 / 10%);
  width: 80px;
  cursor: pointer;
  margin-right: 10px;
}



.parts-table,
.table {
  border: 1px solid $border-color;
  border-radius: 8px !important;

  tr {
    overflow: hidden;
  }

  th {
    background-color: #f8f9fa;
    padding: 12px 14px;
  }

  td {
    padding: 12px 14px;
    border-top: 1px solid $border-color
  }

  .thumbmail-img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 8px;
    border: 1px solid $border-color
  }
}

.quote-buttons {
  display: flex;

  a,
  button {
    width: 130px;
    margin: 10px;
  }

}

.leadTimeRow {
  border-bottom: none;

  #leadTimeTitle {
    font-size: 15px;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
    white-space: nowrap;
    text-align: left;
  }

  input {
    width: 50px;
    margin: 0px 2px;
  }
}


.box-in-quote-version {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  color: $secondary-color;
  margin: 0;
}


.version-tabs {
  margin-top: 2em;
  width: 100%;
}


.my-quotes-div {

  .qoute-version-tabs {
    gap: 24px;
    border-bottom: none !important;

    .nav-link {
      padding: 8px 10px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .tab-div {
        display: flex;
        align-items: center;
        gap: 12px;

        .tab-image {
          width: 28px;
        }
      }
    }

    .nav-link:hover {
      border-color: white;
    }

    .nav-link.active {
      background-color: #f8f9fa;
      border: 1px solid $border-color;
      border-bottom: none;

      .text {
        color: $primary-color;
      }
    }
  }

  .tabsContainer {
    border-bottom: 1px solid $border-color;
  }

  .table-base-container {
    padding: 24px;
    background-color: #f8f9fa;

    .show-total-number {
      margin-left: auto;
      margin-bottom: 12px;
      width: fit-content;
    }
  }

  .table-container {
    border: 1px solid $border-color;
    border-radius: 8px;

    table {
      th {
        padding: 12px 24px;
        border: none;
        border-left: 1px solid $border-color;
      }

      td {
        padding: 12px 24px;
        border: none;
        border-left: 1px solid $border-color;
        border-top: 1px solid $border-color;
        height: 100%;
      }

      .status-badge {
        padding: 6px 24px;
        font-size: 12px;
        background-color: $primary-color !important;
        text-transform: capitalize;

        &.draft {
          background-color: #D3D3D3 !important;
        }

        &.ready-to-order {
          background-color: rgb(62, 185, 62) !important;
        }

        &.submit-target-cost {
          background-color: #ecd654 !important;
        }

        &.completed {
          background-color: $primary-color !important;
        }
      }

      .options-dropdown {
        .dropdown-toggle {
          border: none;
          height: 32px;
          width: 16px;
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            display: none;
          }

          &:hover {
            background-color: transparent;
          }

          &.show {
            background-color: transparent;
          }
        }

        .dropdown-menu {
          border: 1px solid $border-color;
          border-radius: 8px;

          .dropdown-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 12px;
            padding: 8px 24px;
            font-size: 14px;
            border-bottom: 1px solid $border-color;

            &:nth-last-child(1) {
              border: none;
            }

            &:focus,
            &:hover {
              background-color: #f8f9fa;
              color: $text-color;
            }
          }
        }
      }
    }

  }

  .pagination-container {
    width: fit-content;
    margin-left: auto;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .show-total-number {
      margin-bottom: 0;
    }

    .pagination {
      .page-item {
        .page-link {
          font-size: 14px;
          color: $primary-color;
          border: 1px solid $border-color;
          box-shadow: none !important;
        }
      }

      .page-item.active .page-link {
        color: $white-color;
        background-color: $primary-color;
        border: 1px solid $primary-color;
      }
    }
  }
}

.qoute-version-table,
.desc-part-quote-version {
  font-size: 0.8rem;
}



.qoute-version-block {
  justify-content: flex-start;
  text-align: left;
  flex: 1;
  min-width: 200px;

  .qoute-version-label {
    font-weight: bolder;
  }
}

.quote-version-link {
  font-size: 1.1em;
  color: #10a0e7;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}


.line {
  height: 1px;
  width: 100%;
  background-color: #ced4da;
  margin-top: 35px;
  margin-bottom: 35px;
}

.addresses {

  // margin-top: 20px;
  // margin-bottom: 20px;
  .addressTitle {
    margin-bottom: 19px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  .cardContainer {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    // align-items: center;
    .addNewAddressCard {
      margin-right: 24px;
      width: 320px;
      height: 184px;
      display: flex;
      justify-content: center;
      align-items: center;

      background: #f8f9fa;
      border: 1px solid #dee2e6;
      border-radius: 3px;

      .addNewAddressText {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
      }

      @media (max-width:600px) {
        width: 290px;
        margin-bottom: 10px;
      }
    }

    .card {
      margin-right: 24px;
      width: 320px;
      height: 184px;
      background: #ffffff;
      border: 1px solid #dee2e6;
      border-radius: 3px;

      @media (max-width:600px) {
        width: 290px;
        margin-bottom: 10px;
      }

      .cardContainer {
        justify-content: space-between;
      }

      .addressContainer,
      .cardContainer {
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        margin-top: 17px;

        .address-field-title {
          min-width: 300px;
        }

        .name,
        .card-no {
          font-style: normal;
          font-weight: 800;
          font-size: 13px;
          line-height: 120%;
          margin-bottom: 7px;
        }

        .address,
        .card-exp,
        .card-brand {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #333333;
        }

        .card-brand {
          font-weight: 600;
        }
      }

      .addressButtons {
        // display: flex;
        margin-left: 24px;
        margin-top: 14px;

        .btn {
          // border: 1px solid black;
          margin-right: 25px;
          padding: 0px;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #2B96DC;
        }
      }
    }

    @media (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }

    @media (max-width: 760px) {
      grid-template-columns: unset;
    }
  }
}

.MuiPaper-elevation1 {
  box-shadow: unset !important;
}


.qoute-version-table {
  width: 100%;
  border: 1px solid;

  .heading-row {
    height: 70px;

    th {
      background-color: #f4f6f8;
      border: 2px solid white;
      padding: 10px;
    }
  }

  .data-row {
    height: 70px;
    text-align: center;
  }

  td {
    border: 2px solid #f4f6f8;
    padding: 10px;
  }
}

.admin-quote-buttons {
  .download-quote-cost-select {
    width: fit-content;
    height: 30px;
    margin: auto 10px;
    border: 1px solid #c4c4c4;
    background-color: #f7f7f5;
    border-radius: 5px;
  }

}


.order-container {
  .downloadables {
    color: dodgerblue;
    cursor: pointer;
  }

  .white-btn {
    width: auto;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    color: $primary-color;
    padding: 5px 10px;
    background-color: $white-color;
    border: none;
  }

  .order-stage {
    width: 100%;
    text-align: center;

    button {
      background-color: #2B96DC;
      padding: 10px 30px;
      border-radius: 5px;
      color: white;
      transition: 0.2s;

      &:hover {
        transform: scale(1.02);
      }

      &:active {
        transform: scale(0.98);
      }
    }

  }

  border-radius: 5px;

  .header {
    display: flex;
    width: 100%;
    flex-direction: column;

    .left-header {
      width: 100%;
      text-align: left;

      p {
        margin: 15px 0px 0px 0px;
        color: #8d8d8d;
      }

      h5 {
        color: #101823;
      }
    }

    .right-header {
      width: 100%;
      text-align: left;

      p {
        margin: 15px 0px 0px 0px;
        color: #8d8d8d;
      }

      h5 {
        color: #101823;
      }

    }

    .order-button {
      @include blue-btn;
      max-width: 177px;
      text-align: center;
      border: none;
      border-radius: 4px;
      font-size: 15px;
      transition: 0.2s;
      text-decoration: none;
      border: 1px solid #2f81d4;
      margin: 5px 0px;

      &:hover {
        background: #2f81d4;
      }
    }
  }

  .grid-div {
    padding: 20px 20px;
    padding-top: 0px;

    .total-amount-order {
      text-align: end;

      .order-price {
        background-color: rgb(238, 236, 236);
        width: 200px;
        margin-left: auto;
        padding: 10px 5px;
        text-align: center;
        border-radius: 5px;
      }
    }
  }

  .fl-btn {
    flex: 0 0 100px;
    max-width: 100px;

    .file-input__input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .file-input__label {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      border-radius: 0 3px 3px 0;
      font-size: 14px;
      font-weight: 600;
      color: $primary-color;
      font-size: 14px;
      width: 100%;
      margin-top: 0;
      background-color: $white-color;
    }
  }

  .po-file-container {
    .message-box {
      margin-top: 30px;
      text-align: right;

      .mesg-box {
        @include text-bar;
        width: 100%;
        resize: none;
        height: 120px;

        &:focus-visible {
          box-shadow: unset;
          outline: none;
        }
      }

      .update-button {
        padding: 10px 30px;
        margin-top: 10px;
      }
    }

    .message-btns {
      margin-top: 30px;
      padding: 40px 20px;
      text-align: center;
      background: #ececec;

      button.update-button {
        padding: 10px 30px;
      }
    }

    .po-doc-right {
      width: 100%;
      display: flex;
      flex: auto;
      justify-content: center;
      align-items: center;

      .update-button {
        padding: 10px 30px;
      }
    }

    .po-doc-left {
      .attach-file-bx {
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;

        .attach-name {
          flex: 0 0 calc(100%);
          @include text-bar;
          border-radius: 3px 0 0 3px;
          border-color: #dee2e6;
          border-right: none;

          &:focus-visible {
            box-shadow: unset;
            outline: none;
          }
        }
      }

      .po-fl-btn {
        margin-bottom: 10px;
        display: flex;
        flex-wrap: no-wrap;
        justify-content: space-between;

        .upload-po-text {
          width: 250px;
          justify-content: center;
          align-items: center;
        }

        .po-file-input__input {
          width: 0px;
          visibility: hidden;
        }

        .po-file-input__label {
          width: 100px;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          font-size: 14px;
          font-weight: 600;
          color: $primary-color;
          padding: 5px 12px;
          background-color: $white-color;
        }
      }
    }
  }
}


.order-details-grid {
  width: 100%;
  margin: auto;
  border: 1px solid;

  .heading-row {
    height: 70px;

    th {
      background-color: #f4f6f8;
      border: 2px solid white;
      padding: 10px;
    }
  }

  .data-row {
    height: 70px;
    text-align: center;

    .discription-td {
      min-width: 250px;
    }

    .notes-td {
      min-width: 200px;
    }
  }

  td {
    border: 2px solid #f4f6f8;
    padding: 10px;
  }
}


.po-dropzone {
  width: 50%;
  max-width: 500px;
}

.order-box-holder .box-in .or-details .processing-box .price-box .white-border {
  border-color: white;
}

.bulk-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
  gap: 20px;

  .bulk-button {
    height: 38px;
    margin-top: 21px;
    margin-left: 10px;
    padding: 8px 16px;
    background: rgb(37, 104, 167);
    color: $white-color;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
  }
}

.form-input {
  outline: none;
  height: 34px;
}


.orderAndPayment {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.accordion-icon {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.accordion-title {
  // display: flex;
  // justify-content: space-between;
  position: relative;

  .deliveryTitle {
    opacity: 1;
  }
}

.accordion-total {
  width: 200px;

  .subtotal {
    display: flex;
    justify-content: space-between;
  }

  .total {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .total p {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
  }
}

.order-stage-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .stage-button {
    background-color: $primary-color;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    margin: 10px;
  }

  .supplier-stage-button {
    background-color: $complementary-primary-color;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    margin: 10px;
  }


  .supplier-stage-button:hover {
    background-color: $complementary-hover-color;
  }

  .supplier-stage-button:active {
    background-color: $complementary-hover-color !important;
  }

}


.quality-doc-div {
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-wrap: wrap;

  .child-item {
    flex: 1;
    flex-basis: 0;
  }

  input[type="file"] {
    visibility: hidden;
    display: none;
  }

  input[type="text"] {
    padding: 5px 10px;
    border: 1px solid;
    border-radius: 2px;
  }

  .upload-doc-btn {
    background-color: $primary-color;
    color: white;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
    margin: 5px;
  }
}


.po-upload-doc-div {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .po-dropzone {
    width: 50%;
  }

  .po-consignment-pic-right {
    width: 50%;
    padding: 20px 30px;

    .file-grid {
      display: flex;
      flex-wrap: wrap;

      .file-name {
        width: 150px;
        overflow-wrap: break-word;
        font-size: 10px;
        text-align: center;
        margin: 5px 3px;
      }

    }
  }
}


.delivery-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
  padding: 0px 20px;

  .delivery-tracking-div {
    text-align: left;
    flex-wrap: wrap;
    max-width: 400px;
  }
}

.tabsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;

  .tabs-bar {
    width: 100%;
  }
}

.white-btn {
  width: auto;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $primary-color;
  background-color: $white-color;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 5px;
  margin-right: 10px;
  border: none;
  box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);
}

.raise-issue-div {
  .col {
    padding-right: 7px;
    padding-left: 7px;

    .consignmentDiv {
      display: flex;
      flex-wrap: wrap;

      .consignmentCard {
        width: 200px;
        padding: 5px;
        text-align: center;
        margin: 5px;
        box-shadow: 5px 5px 5px rgb(232, 232, 232);
        transition: 0.3s;
      }

      .consignmentCard:hover {
        transform: scale(1.05);
      }

      .downloadables {
        font-size: 10px;
      }
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 13px;
      color: rgba($secondary-color, 70%);

      a {
        color: $primary-color;
      }
    }

    table {
      margin-top: 30px;
      min-width: 100%;
      text-align: center;
      border: gray;

      tr {

        // background-color: red;
        td {
          padding: 20px 20px 20px 20px;
        }
      }
    }
  }

  .progress-dots {
    margin-bottom: 8px;
    .dot {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 8px;
    }

    .active {
      border: 2px solid $primary-color;
    }

    .inactive {
      border: 2px solid $border-color;
    }

    .complete {
      border: 2px solid $primary-color;
      background-color: $primary-color;
    }
  }
}

.raise-issue-part-div {
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;

  .raise-issue-item {
    width: calc(33% - 16px);

    .box-pr
      {
        label {
          width: 100%;
          display: flex;
          gap: 8px;
          justify-content: flex-start;
          border: 1px solid $border-color;
          border-radius: 8px;
          box-shadow: none;

          .img-div {
            width: 40px;
            .thumbnail {
              width: 40px;
              height: 40px;
              border: 1px solid $border-color;
              border-radius: 8px;
            }
          }
          .text-div {
            width: calc(100% - 40px);

            p {
              color: $text-color;
              text-align: left;
              line-break: anywhere;
            }
          }
        }

        &.pr-checked {
          label {
            border: 1px solid $primary-color;
          }
        }
    }
  }
}

.raise-an-issue-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
}

.raise-issue-check-div {
  margin-top: 40px;
  input[type=checkbox] + label:before {
    margin-right: 8px;
  }
}


.quality-related-issue {
  margin-top: 30px;
  padding: 10px;
  border-radius: 3px;
  background-color: #f9f8f8;

  p {
    font-size: 14px;
    font-weight: bold;
  }

  .price-box {
    margin-top: -8px;
    text-align: center;
    min-width: 190px;

    .box-in {
      &:hover {
        background-color: white;
      }
    }
  }
}


.issue-description-div {
  .issue-description {
    width: 100%;

    textarea {
      margin: auto;
      width: 100%;
      height: 280px;
      border: 1px solid $border-color;
      border-radius: 8px;

      &:focus-visible {
        border: 1px solid $primary-color !important;
        outline: none;
      }
    }

  }

  .downloadables {
    color: dodgerblue;
    cursor: pointer;
    font-size: 14px;

    .issue-file-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 250px;
      padding: 10px;
      border-radius: 5px;
      background-color: #f9f8f8;
      margin: 5px;
    }

    .file-list {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }
  }
}

.shipment-details {
  border: 1px solid $border-grey;
  background-color: #f9f8f8;
  padding: 20px;
  margin-top: 30px;
  border-radius: 5px;
  width: 100%;
  overflow-x: auto;
  min-width: 230px;

  .shipment-details-header {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: white;
    padding-top: 20px;
    min-width: 300px;
  }

  .tracking-details-div {
    margin-top: 30px;
    font-size: 13px;

    .close-btn {
      padding: 8px 16px;
      background: $primary-color;
      color: $white-color;
      font-size: 14px;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      margin-right: 10px;
    }

    table {
      width: 100%;

      .table-headers {
        text-decoration: underline;

        th {
          padding: 0px 20px;
        }
      }

      .details-row {
        margin: 5px;
        border: 1px solid rgb(174, 173, 173);
        padding: 5px;

        td {
          padding: 0px 10px;
          background-color: white;
        }

        .sr-no-td {
          background-color: gray;
          width: 40px;
          text-align: center;
        }

        .piece-td {
          text-align: center;
          width: 40px;
        }

        .description-td {
          max-width: 400px;
        }
      }
    }
  }
}


.share-quote-div {
  select {
    width: 50%;
    padding: 6px;

    option {
      padding: 10px;
    }
  }

  .add-email-div {

    input,
    button {
      padding: 4px 8px;
    }

    input {
      width: 350px;
    }

    button {
      background: $primary-color;
      color: white;
      border: 2px solid $primary-color;
      border-radius: 5px;
      // margin-left: 20px;
    }
  }

  .share-content-div {
    margin-top: 20px;

    .email-list-share-div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .email-item {
        background-color: #f5f5f5;
        min-width: 300px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-radius: 8px;
        margin: 10px 10px 10px 0;

        .close-img {
          cursor: pointer;
        }
      }
    }
  }

  .follower-input {
    width: 50%;
  }

  .follower-drop-down-div {
    position: absolute;
    background-color: white;
    width: calc(50% - 16px);
    border: 1px solid black;
    margin-top: 0px;
    border-radius: 3px;

    li {
      text-decoration: none;
    }

    .follower-drop-down {
      p {
        margin-bottom: 0px;
        padding-left: 5px;
        font-size: 15px;
      }

      cursor: pointer;
    }

    .follower-drop-down:hover {
      background-color: #2B96DC;
      color: white;
    }
  }

  // .follower-drop-down{
  //   position:absolute;
  //   display: block;
  // }
}

.share-quote-div-footer {
  display: flex;
  justify-content: space-between;

  .share-button {
    cursor: pointer;
    padding: 8px 16px;
    background: $primary-color;
    color: $white-color;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
  }
}



.trk-unavailable {
  button {
    background: #FFFFFF;
    width: 180px;
    color: #2B96DC;
    border: 1px solid rgba(226, 228, 232, 0.4);
    box-shadow: 0px 8px 16px rgb(30 52 92 / 10%);
    border-radius: 4px;
    height: 36px;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    margin-bottom: 10px;
  }
}

.issue-div {
  width: 100%;
  background-color: #f4f4f4;
  padding: 20px;
  margin: 10px 5px;
  display: flex;
  justify-content: space-between;


  .issue-col {
    width: 100%;

    .issue-description {
      font-size: 13px;
      width: 100%;
      border: 1px solid rgb(159, 159, 159);
      min-height: 100px;
      margin-top: 0px;
      margin-bottom: 10px;
    }

    ul {
      margin-bottom: 30px;

      li {
        font-size: 13px;
      }

    }

    p {
      font-size: 13px;
    }

    .issue-button {
      max-width: 100px;
      cursor: pointer;
      padding: 8px 16px;
      background: $primary-color;
      color: $white-color;
      font-size: 14px;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      margin: 10px 0px;
    }
  }
}

.issue-div-admin {
  display: flex;
  justify-content: space-between;
  text-align: left;

  .issue-col-admin {
    min-width: 33%;

    .item-description {
      word-break: break-all;
    }

    p,
    li {
      font-size: 12px;
    }

    .issue-button {
      max-width: 100px;
      cursor: pointer;
      padding: 8px 16px;
      background: $primary-color;
      color: $white-color;
      font-size: 14px;
      font-weight: 500;
      border: none;
      border-radius: 5px;
      margin: 10px 0px;

    }
  }
}


.downloadables {
  color: dodgerblue;
  cursor: pointer;
}

.admin-quote-create {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgb(158, 158, 158);
  padding: 10px;
  margin: 10px 5px;
  align-items: center;
}

.my-order-address {
  .name {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #333333;
  }

  .add {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #333333;
    opacity: 0.6;
  }

}

.dashboard-option {
  align-items: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 20px 50px 20px 60px;

  .option-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;

    a {
      color: white;
    }

    .option-div {
      background-color: $primary-color;
      margin: 30px;
      border-radius: 5px;
      border: 1px solid #1f84c7;
      padding: 25px;
      cursor: pointer;
      width: 400px;
      height: 250px;

      .option-header {
        font-family: 'Red Hat Display';
        font-size: 30px;
        text-align: center;
        font-weight: 1000;
      }
    }

    .option-div:hover {
      transform: scale(1.03);
      background-color: #1f84c7;
      transition: 0.3s;
    }

    .option-div:active {
      transform: translateY(2px);
    }
  }



  .dash-left {
    flex: 0 0 55%;
    max-width: 55%;

    .ds-heading {
      font-size: 25px;
      margin: 0 0 40px 0;
      padding: 0;
      font-weight: 400;
      line-height: 39px;
    }
  }
}


.process-select {
  padding: 10px;
  margin: 10px 20px;
}

.bucketFilterDiv {
  display: flex;
  text-align: left;
  justify-content: flex-start;
  flex-wrap: wrap;

  .bucketFilterOptions {
    width: 250px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    margin: 10px;
    box-shadow: 2px 2px 2px 2px rgb(231, 231, 231);
    transition: 0.3s;
    display: flex;
    justify-content: space-around;

    .filterIcon {
      width: 70px;
      height: 70px;
      background-color: #F4F6F8;
      padding: 15px;
      border-radius: 27px;
      margin: 10px;
    }

    .textBox {
      // background-color: red;
      display: flex;
      flex-direction: column;
      padding: 5px 10px;

      p {
        font-size: 12px;
        color: rgb(122, 122, 122);
        margin: 5px;
      }

      h5 {
        font-size: 2rem;
        margin-left: 5px;
      }
    }
  }

  .bucketFilterOptions:hover {
    transform: scale(1.01);
  }

  .bucketFilterOptions:active {
    transform: translateY(3px)
  }

  .selected {
    background-color: #2B96DC;

    .textBox {
      // background-color: red;
      display: flex;
      flex-direction: column;
      padding: 5px 10px;

      p {
        font-size: 12px;
        color: rgb(255, 255, 255);
        margin: 5px;
      }

      h5 {
        font-size: 2rem;
        margin-left: 5px;
        color: white;
      }
    }
  }
}


.supplierTable {
  width: 100%;
  font-size: 0.9rem;

  th {
    border: 1px solid gray;
    padding: 5px;
    text-align: left;
  }

  td {
    border: 1px solid gray;
    padding: 5px 10px;
    height: 75px;
    text-align: left;

    select {
      text-align: left;
      padding: 10px 20px;
      margin: 0;
      min-width: 200px;
      max-width: 400px;
    }

    button,
    .rfq-doc-label {
      @include blue-btn;
      background-color: $complementary-primary-color;
      margin-top: 0;
      max-width: 200px;
      margin-left: auto;
      color: #fff;
      font-size: 14px;
      border-radius: 4px;
      padding: 5px 10px;
      border: none;
    }

    .rfq-doc-label:hover,
    button:hover {
      background-color: $complementary-hover-color;
    }
  }

  .right-td {
    text-align: right;
  }
}

.supplierButton {
  background-color: $complementary-primary-color;
  border: 1px solid $complementary-primary-color;
}

.supplierButton:hover {
  background-color: $complementary-hover-color;
}

.supplierButton:active {
  background-color: $complementary-hover-color !important;
}

.common-supplier-button {
  @include blue-btn;
  background-color: $complementary-primary-color;
  margin-top: 0;
  max-width: 170px;
  margin-left: auto;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  padding: 6px 10px;
  border: none;
  margin-right: 10px;
  margin-top: 5px;
}

.common-supplier-button:hover {
  background-color: $complementary-hover-color;
}

.common-supplier-button:active {
  background-color: $complementary-hover-color !important;
}

.custom-switch {
  transform: scale(1.1);
}

.csr-section {
  max-width: 960px;
  margin: 40px auto 30px;
  padding: 40px 50px;
  background: #fff;
  border-radius: 10px;

  h2 {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 24px;
    color: $secondary-color;
  }

  p {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 14px;
    line-height: 22px;
    color: rgba($secondary-color, 70%);
  }

  h5 {
    font-size: 18px;
    margin: 25px 0 10px 0;
    padding: 0;
    color: $secondary-color;
  }

  .u-line {
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
  }

  .ui-text {
    text-decoration: underline;
    font-style: italic;
  }

  ul,
  ol {
    margin: -10px 0 30px 0;
    padding: 0 0 0 30px;
    font-size: 14px;
    line-height: 22px;
    color: rgba($secondary-color, 70%);
  }
}

.req-quote-btn-new {
  @include blue-btn;
  display: inline-block;
  width: auto;
  padding: 5px 10px;
}

.custom-modal-view-order {
  max-width: 350px;
  width: 95%;
}

.custom-modal-view-order2 {
  width: 95%;
  width: 550px;
}

.dashboard-div {

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .header-div {
      min-width: 200px;
      margin-top: 20px;
      text-align: left;

      .req-quote-btn {
        min-width: 180px;
      }

      select {
        margin-top: 3px;
      }
    }
  }

  .content-container {
    .order-tracker {
      margin-top: 30px;
      text-align: left;

      h3 {
        border-radius: 5px 5px 0px 0px;
        padding: 5px;
        font-weight: bolder;
        margin: 0px 5px;
      }

      .header-div {
        h3 {
          border-radius: 5px 5px 0px 0px;
          padding: 10px;
          font-weight: bolder;
          margin: 0px 10px;
        }

        .customer-filter-div {
          margin: 0px 10px;
          padding: 10px;
          display: flex;
          flex-wrap: wrap;

          .filter-item {
            flex: 1;
            padding: 20px;
            max-width: 45%;
            min-width: 300px;

            label {
              margin-bottom: 10px;
              font-weight: bold;
            }
          }


        }
      }


      .row-div {
        padding: 10px;
        margin-top: -9px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-items: flex-start;

        .col-div-container {
          flex: 1;
          text-align: left;
        }

        .col-div {
          flex: 1;
          text-align: left;
          border: 1px solid rgb(205, 205, 205);
          box-shadow: 3px 3px 3px rgb(206, 206, 206);
          margin: 20px 10px;
          padding: 20px 10px;

          h5 {
            text-decoration: underline;
          }

          table {
            font-size: 0.8rem;
            width: 100%;

            tr {
              border-radius: 3px;
              border-bottom: 1px solid gray;
              width: 100%;
              cursor: pointer;

              th {
                padding: 10px;
              }

              td {
                padding: 10px;
              }

              .label-td {
                font-weight: bold;
              }
            }
          }
        }

      }
    }
  }


}


.table-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #fff;
  flex: 0 0 100%;
  text-align: center;

  .heading-row {
    height: 70px;

    th {
      background-color: #f4f6f8;
      border: 2px solid white;
      padding: 10px;
    }
  }

  table {
    width: 100%;
    background: #fff;
    border-radius: 8px !important;

    tr {
      height: 30px;
      text-align: left;

      th {
        font-weight: bolder;
        font-size: 12px;
        color: #6b6b6b;
        border: 1px solid #F4F6F8;
        width: 200px;
      }

      td {
        border: 1px solid #F4F6F8;
        color: #302f2f;
        font-size: 13px;
        font-weight: 500;
        text-align: left;
        padding: 2px 5px;
        height: 30px;
      }

    }
  }

  .order-grid-table {
    width: 100%;
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;
    background: #fff;

    // flex: 0 0 100%;
    // max-width: 100%;
    tr {
      th {
        height: 56px;
        font-weight: 500;
        font-size: 11px;
        width: 100%;
        color: #858585;
        border: 1px solid #F4F6F8;
      }

      td {
        width: 100%;
        border: 1px solid #F4F6F8;
        color: #333333;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}



.price-tab-my-quote {
  display: flex;
  flex: 1;
  gap: 10px;

  .box-pr {
    width: 100%;
  }

  .box-in {
    padding: 6px 3px;
    min-height: 50px;

    .price-sp {
      width: 100%;
      display: inline-block;
      text-align: center;
      font-size: 15px;
      position: relative;
      font-weight: 500;
      color: #555555;


    }

    .vld-bx {
      font-size: 10px;
      width: 100%;
      display: inline-block;
      color: rgba(85, 85, 85, 0.87);
    }

    .ship-bx {
      width: 100%;
      display: inline-block;
      font-size: 10px;
      color: rgba(85, 85, 85, 0.6);
    }
  }
}

.updated-search-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.bill-form-container {

  .bill-form-row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    // border: 2px solid red;


    div {
      flex: 1;
      overflow: hidden;
      min-width: 200px;
      margin-left: 2px;
      text-align: left;

      label,
      button {
        font-size: 12px;
        color: #858585;
        max-width: 200px;
        font-weight: bold;
      }

      .check-label {
        font-size: 12px;
        color: #ff4a4a;
        font-weight: bold;
        width: 100%;
        display: inline;
      }

      .bill-button {
        margin: 0px;
        border: 1px solid #b4b4b4;
        margin-top: 3px;
        display: block;
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        color: #2B96DC;
      }

      input {
        max-width: 200px;
        border-radius: 3px;
        border: 1px solid #eaedf0;
        font-size: 13px;
        padding: 5px;
        font-weight: 400;
      }

      button {
        border: 1px solid #b4b4b4;
        color: $primary-color;
      }
    }

  }


}


.photo-view-div {
  display: inline;
  text-align: center;

  .photo-viewer-card {
    height: 200px;
    width: 250px;
    margin: 10px;
    border-radius: 5px;
    transition: 0.3s;
  }

  .photo-viewer-card:hover {
    transform: scale(1.05);
  }
}

.blue-btn-ui {
  background-color: #2B96DC !important;
  text-align: center;
  color: #fff !important;
  border-radius: 3px;
  padding: 5px 10px;
}


.highlight-row {
  background-color: $primary-color;
  color: rgb(227, 223, 223);
}


.qoute-version-table-admin {
  width: 100%;
  border: 1px solid;

  thead {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .heading-row {
    border: 1px solid #000000;

    th {
      background-color: #bebebe;
      ;
      color: #000000;
      padding: 3px;
    }
  }

  .data-row {
    text-align: center;
  }

  td {
    font-size: 0.8em;
    border: 1px solid #cacaca;
  }
}

.create-instant-quotation {
  min-height: calc(100vh - 64px);
  display: flex;

  .left-section {
    height: fit-content;
    flex: 0.7;
    min-height: calc(100vh - 64px);
    position: sticky;
    top: 64px;

    .process-title {
      margin-bottom: 16px;
      font-size: 20px;
      color: #333333;
      font-weight: 600;
    }

    .process-tabs-container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .process-tab {
        .label {
          width: 100%;
          padding: 10px 12px;
          border: 1px solid $border-grey;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 400;
          color: $text-color;
          cursor: pointer;
        }

        .label.selected {
          border: 2px solid $primary-color;
          font-weight: 500;
        }
      }
    }

    .upload-box {
      margin-top: 28px;
      padding: 16px;
      height: fit-content;
      gap: 12px;

      .up-icon {
        height: 50px !important;

        img {
          width: 100px;
        }
      }
    }
  }

  .file-details-section {
    padding: 0 24px 24px 24px;
  }

  .top-section {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    border-bottom: 1px solid $border-grey;
    position: sticky;
    top: 64px;
    background: $white-color;
    z-index: 1000;

    .process-tabs-div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
      width: 27%;

      .process-tabs-container {
        width: 100%;
      }
    }

    .upload-req {
      width: 73%;

      .dropzone {
        margin: 0;
        padding: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: fit-content;
        height: fit-content;

        .up-icon {
          height: 46px;
        }
      }
    }
  }

  .full-screen-file-drop .dropzone {
    z-index: 10001 !important;
  }

  .dropzone {
    height: calc(100vh - 312px);
    gap: 16px;

    .up-icon {
      height: 70px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .inp-txt {
      margin: 0;
      font-size: 24px;
      font-weight: 600;
      color: $text-color;
    }

    .file-input__label {
      margin: 0;
      border: 1px solid $border-grey;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;
      color: $primary-color;
    }

    .info-text {
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      color: #858585;
    }
  }

  .privacy-text {
    padding: 12px;
    font-size: 12px;
    color: #858585;
    font-weight: 400;
    border-radius: 0px;
    box-shadow: none;
    border-top: 1px solid $border-grey;
  }

  .right-section {
    height: calc(100vh - 64px);
    flex: 0.85;
    position: sticky;
    top: 64px;
    padding: 24px 24px 0 24px;
    overflow: scroll;
    scrollbar-width: none;

    .price-tabs {
      height: 100%;
      justify-content: space-between;
    }

    .delivery-options {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .delivery-option {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .title-col {
          display: flex;
          align-items: center;
          gap: 8px
        }
      }
    }

    .shipping-div {
      margin: 16px 0;
      padding: 0 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-col {
        display: flex;
        align-items: center;
        gap: 8px
      }
    }

    .total-div {
      border-top: 1px solid $border-grey;
      // padding-top: 16px;

      .text {
        font-size: 16px;
      }
    }

    .estimated-delivery {
      background-color: #f8f9fa;
      text-align: center;
    }

    .certification-section {
      margin: 0 0 0px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .certification-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .icon {
          width: 16px;
        }
      }

      ol {
        padding-left: 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .right-col-cta-section {
      margin-top: 20px;
      padding-bottom: 24px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .certification-btn,
      .checkout-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .icon {
          width: 16px;
        }
      }

      .dropdown {
        .dropdown-toggle {
          &::after {
            display: none;
          }

          &.show, &:hover, &:focus {
            background-color: $white-color;
            border: 1px solid $border-color;
          }
        }

        .dropdown-menu {
          border-radius: 8px;
          border: 1px solid $border-color;

          .dropdown-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 400;
            color: $text-color;

            svg {
              width: 16px;
            }

            &:hover, &:focus, &:active {
              background-color: $border-color;
            }
          }
        }
      }
    }

    .update-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: 100%;

      .icon {
        width: 16px;
      }
    }

    .chat-btn {
      width: calc(100% - 50px) !important;
    }

    .more-options-btn {
      min-width: fit-content;

      svg {
        width: 20px;
        height: 24px;
      }
    }

    .more-options-dropdown {
      position: absolute;
      background-color: $white-color;
      border: 1px solid $border-grey;
      border-radius: 8px;
      transform: translate(-100%, -100%);
      left: -8px;
      top: 48px;
      overflow: hidden;

      button {
        width: 100%;
        padding: 12px 16px;
        text-align: center;
        border: none;
        background-color: $white-color;
        color: $text-color;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .icon {
          width: 14px;
        }

        &:hover {
          background-color: $border-grey;
        }
      }
    }
  }

  .file-details-section {

    .project-name-section {
      padding: 20px 0 10px 0;
      position: sticky;
      top: 194px;
      background-color: $white-color;
      border-bottom: 1px solid $border-color;
      z-index: 1000;
    }

    .project-name {
      align-items: center;

      .icon {
        width: 16px;
        height: 14px;
      }
    }

    .version-select {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border: 1px solid $border-grey !important;
      border-right: none !important;
    }

    .new-version-btn {
      margin-left: 0 !important;
      min-width: fit-content;
      height: fit-content;
      padding: 6px 12px;
      display: flex;
      align-items: center;
      gap: 4px;
      border: 1px solid $border-grey !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;

      .icon {
        width: 16px;
      }
    }

    .item-cards-list {
      margin-top: 8px;
      padding: 0;
      border: none;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .item-card {
        background-color: #f8f9fa;
        border: 1px solid $border-grey;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        align-items: flex-start;
        gap: 12px;

        .no-preview-div {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white-color;
          border-radius: 8px;
        }

        .apply-to-all-switch {
          border: 1px solid #e5e5e5;
          border-radius: 8px;
        }

        .quantity-input {
          width: 64px;
          padding: 6px 12px;
          font-size: 14px;
          color: $text-color;
          border: 1px solid $border-color;
          border-radius: 8px;
        }

        .quantity-input:focus,
        .quantity-input:focus-visible {
          border: 1px solid $primary-color;
          outline: none;
        }

        .quantity-input:disabled {
          background-color: $white-color;
        }

        .img-col {
          width: 9%;
          position: relative;

          .thumbnail {
            border: 1px solid $border-color;
          }

          button {
            position: absolute;
            bottom: -14px;
            padding: 0;
            border: none;
            background-color: transparent;
            font-size: 10px !important;

            svg {
              margin-left: 2px;
              width: 10px;
              height: 10px !important;
            }
          }

          .loader {
            width: 64px;
            height: 64px;
          }
        }

        .text-col {
          width: 91%;
        }

        select:disabled {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: $white-color;
          background-image: none;
        }

        .thumbnail {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }

        .file-button {
          width: fit-content;

          label {
            margin-top: 8px;

            span {
              color: $primary-color;
            }
          }
        }

        .upload-btn-loading {
          margin-top: 8px;

          svg {
            stroke: $primary-color;
          }
        }

        .upload-2d-file-div {
          width: fit-content;
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: 8px;

          a {
            display: flex;
            align-items: center;
            gap: 8px;
            color: $primary-color;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;

            .icon {
              width: 16px;
            }
          }

          svg {
            cursor: pointer;
          }
        }

        .config-details-col {
          display: grid;
          grid-template-columns: 28% 28% 28%;
          gap: 8px 8px;
          width: 55%;
          justify-content: left;

          .config-item {
            .text {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .form-box {
    margin-top: 16px;
    padding: 16px 0 0 0;
    border-top: 1px solid $border-grey;
    border-radius: 0px;

    .edit-btn {
      font-size: 12px;
      line-height: 12px;
    }
  }
}

// modal

.upload-sample-modal {
  .sample-cards-div {
    display: flex;
    gap: 10px;

    .sample-card {
      // width: 25%;
      padding: 10px;
      border: 1px solid $border-grey;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
      gap: 10px;

      .sample-card-content {
        display: flex;
        flex-direction: column;
        gap: 8px;

        img {
          margin:auto;
          width: 50%;
          margin-bottom: 8px;
          border-radius: 8px;
          border: 1px solid $border-color;
        }

        .title-text {
          font-size: 18px;
          font-weight: 600;
        }

        .description-text{
          font-size: 14px;
        }
      }

      .primary-btn {
        width: 100%;
      }
    }
  }
}

// global

.primary-btn {
  padding: 6px 10px;
  color: $white-color;
  font-size: 16px;
  font-weight: 400;
  background-color: $primary-color;
  border-radius: 8px;
  border: 1px solid $primary-color;
  min-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;

  &:disabled {
    opacity: 0.6;
  }
}

.border-btn {
  padding: 6px 10px;
  color: $black-color;
  font-size: 16px;
  font-weight: 400;
  background-color: $white-color;
  border-radius: 8px;
  border: 1px solid $border-color;
  min-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.grey-btn {
  padding: 8px 12px;
  color: $black-color;
  font-size: 16px;
  font-weight: 400;
  background-color: $border-color;
  border-radius: 8px;
  border: 1px solid $border-color;
  min-width: 100px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.link-btn {
  padding: 0;
  color: $primary-color !important;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.chat-border-btn {
  background-color: $white-color !important;
  color: #333333 !important;
  p{
    color: #333333 !important
  }
}

.info-text {
  font-size: 12px;
  color: #858585;
  font-weight: 400;
}

.text {
  font-size: 14px;
  color: $text-color;
  font-weight: 400;
}

.sub-header {
  font-size: 20px;
  color: $black-color;
  font-weight: 600;
}

.header-text {
  font-size: 24px;
  color: $text-color;
  font-weight: 600;
}

.modal .modal-dialog {
  width: 60%;
  border-radius: 8px;

  .modal-header,
  .modal-body,
  .modal-footer {
    border: none;
  }

  .modal-header {
    padding: 24px 24px 12px 24px;
  }

  .modal-body {
    padding: 12px 24px;
  }

  .modal-footer {
    padding: 12px 24px 24px 24px;
  }
}

.modal.edit-project-name-modal .modal-dialog {
  width: 30%;
}

.download-quote-modal {
  .address-row {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;

    .address-card {
      width: calc(50% - 12px);
      height: fit-content;
      padding: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-radius: 8px;
      border: 1px solid $border-grey;
      cursor: pointer;

      &.active {
        border: 2px solid $primary-color;
      }

      .cta-div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        min-width: 120px;
      }
    }

    .add-new-address-card {
      padding: 0;
      width: fit-content;
      height: fit-content;
    }
  }
}

.project-name-component {
  .project-name {
    position: relative;

    .full-name {
      background: $border-color;
      position: absolute;
      left: 0;
      bottom: calc(-100% - 8px);
      padding: 4px 8px;
      width: max-content;
      color: $text-color;
      border-radius: 4px;
      font-size: 12px;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover {
      .full-name {
        opacity: 1;
      }
    }
  }
}

.ant-select-selector,
.ant-input-affix-wrapper {
  font-family: "Red Hat Display", serif !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  // border: 1px solid $border-color !important;
}

.ant-input {
  font-family: "Red Hat Display", serif !important;
  font-size: 14px;
  color: $text-color;
  border-radius: 0px !important;
}

.ant-select-selector:focus,
.ant-input-affix-wrapper:focus {
  border: 1px solid $primary-color !important;
  box-shadow: none !important;
}

.ant-select-disabled .ant-select-selector {
  background-color: $white-color !important;
  color: $text-color !important;
}

.ant-select-disabled .ant-select-arrow {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .modal .modal-dialog {
    width: 96%;
  }

  .sample-cards-div {
    flex-direction: column;
    gap: 16px;

    .sample-card {
      width: 100% !important;
    }
  }
}


.editor {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  min-height: 150px;
  max-height: 300px;
  overflow-y: auto;
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-wrap;
}

.toolbar {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.toolbar button {
  padding: 5px 10px;
  cursor: pointer;
}


.chat-drawer {
  * {
    font-family: "Red Hat Display", serif !important;
    color: $text-color;
  }

  .offcanvas-body {
    padding: 0;
  }

  .chat-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .input-div {
      padding: 12px 24px;
      background-color: #f8f9fa;
      position: relative;
      border-top: 1px solid $border-color;

      .attachment-btn {
        position: absolute;
        right: 28px;
        top: 18px;
      }
    }
  }

  .cs-main-container {
    margin-top: 34px;
    height: 100%;
    border: none;

    .ant-tabs-nav-wrap {
      margin-top: 30px;

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: $primary-color;
        }
      }

      .ant-tabs-tab {
        .ant-tabs-tab-btn {
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }

  .quill {
    background-color: $white-color;
    border: 1px solid $border-color;
    border-radius: 8px;

    .ql-snow {
      border: none;
    }

    .ql-container {
      border-top: 1px solid $border-color !important;
    }

    .ql-editor {
      min-height: 80px;

      p {
        font-size: 14px
      }
    }
  }
}

.cs-message--outgoing .cs-message__content,
.cs-message--incoming .cs-message__content {
  background-color: $border-color !important;
  font-size: 14px;

  img {
    border-radius: 8px;
  }

  a {
    color: $primary-color;
  }

  h1 {
    font-size: 26px;
    font-weight: 700;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }

  ol {
    list-style-type: decimal;
    padding-left: 20px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
}

.ant-image-preview-wrap {
  background-color: $text-color;
}

.no-img-div {
  height: 64px;
  width: 64px;
  background-color: #f9f8f8;
  border: 1px solid $border-color;
  border-radius: 8px;
}

.quotation-details {
  flex-direction: column;
  .top-section {
    width: 100%;
    height: fit-content;
    min-height: 100px;
    border-bottom: 1px solid $border-color;
  }

  .tab-container {

    .version-tabs {
      margin-top: 0;

      .tab-header {
        padding: 24px 24px 0 24px;
        background-color: $white-color;
        border-bottom: 1px solid $border-color;
        position: sticky;
        top: 164px;
        z-index: 50;

        .nav-link.active {
          color: $primary-color;
          background-color: #f8f9fa;
          border: 1px solid $border-color;
        }
        .nav-link:hover {
          color: $primary-color;
          border: 1px solid $white-color;
          border-bottom: 1px solid $border-color
        }
        .nav-link:focus {
          color: $primary-color;
        }
      }

      .tab-body {

        .quote-details-table-div {
          display: flex;
          align-items: flex-start;

          .table-div {
            flex: 3;
            padding: 24px;

            .table-container {
              border: 1px solid $border-color;
              border-radius: 8px;
              overflow: hidden;
          
              .table {
                background: $white-color;
                margin: 0;
                border: none;
                th {
                  padding: 12px 24px;
                  border: none;
                  border-left: 1px solid $border-color;
                  background-color: $white-color !important;
                }
          
                td {
                  padding: 12px 24px;
                  border: none;
                  border-left: 1px solid $border-color;
                  border-top: 1px solid $border-color;
                  height: 100%;

                  .thumbnail {
                    width: 80px;
                  }
                }

                .file-col {
                  .downloadables {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    .link-btn {
                      svg {
                        width: 16px;
                      }
                    }
                  }
                }
              }
            }
          }

          .qoute-version-price-div {
            flex: 0.8;
            overflow: hidden;
            padding: 24px;
            height: calc(100vh - 227px);
            background: $white-color;
            border-left: 1px solid $border-color;
            position: sticky;
            top: 227px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .price-tabs {
              flex-direction: column;
              gap: 16px;

              .pr-checked .box-in {
                border: 2px solid $primary-color !important;
              }

              .pr-radio {
                margin: 0;

                .box-in {
                  box-shadow: none;
                  border: 1px solid $border-color;
                  border-radius: 8px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  min-height: fit-content;

                  &:hover {
                    background-color: $border-color;
                  }

                  .price-sp {
                    width: fit-content;
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: $text-color;

                    &::before {
                      display: none;
                    }
                  }

                  .vld-bx {
                    width: fit-content;
                    margin: 0;
                    font-size: 12px;
                    color: #858585;
                  }
                }
              }
            }
          }

          .chat-border-btn {
            background-color: $white-color;
            color: #333333 !important;
            p{
              color: #333333 !important
            }
          }

          #more-options-toggle {
            min-width: 36px;
            height: 100%;

            &::after {
              display: none;
            }

            &:hover, &:focus, &.show {
              background-color: $white-color;
              color: $text-color !important;
              border: 1px solid $primary-color;
            }
          }

          .dropdown-menu {
            border: 1px solid $border-color;
            border-radius: 8px;

            .dropdown-item {
              padding: 8px 12px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 8px;
              font-size: 14px;
              color: $text-color;

              svg {
                width: 16px;
              }

              a {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                font-size: 14px;
                color: $text-color !important;
              }

              &:active, &:hover, &:focus {
                background-color: $border-color
              }
            }
          }
        }



      }
    }
  }
}

.payments-screen {
  .intro-bar {
    .int-right {
      display: flex;

      .text {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .dashboard-holder {
    .common-bx {
      box-shadow: none;
      border: 1px solid $border-color;
      background-color: $white-color;
      border-radius: 8px;

      .btn-div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
      }

      .pl-form {
        padding: 0;
        background-color: $white-color;

        label {
          font-size: 14px;
          opacity: 1;
          margin-bottom: 4px;
        }

        .file-button label {
          border-radius: 8px;
          border: 1px dashed $border-color;
        }

        input[type=checkbox] + label:before {
          margin-right: 8px;
        }
      }
    }
  }
}

.quote-version-modal {

  .modal-dialog {
    max-width: 1000px;
  }
  .table-container {
    border: 1px solid $border-color;
    border-radius: 8px;
    overflow: hidden;

    table {
      background: $white-color;
      margin: 0;
      border: none;
      th {
        padding: 12px 24px;
        border: none;
        border-left: 1px solid $border-color;
        background-color: $white-color !important;
      }

      td {
        padding: 12px 24px;
        border: none;
        border-left: 1px solid $border-color;
        border-top: 1px solid $border-color;
        height: 100%;
      }
    }

  }
}

.download-quote-modal {
  .price-tabs {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .pr-checked .box-in {
      border: 2px solid $primary-color !important;
    }
    .box-in {
      box-shadow: none;
      border: 1px solid $border-color;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: fit-content;

      &:hover {
        background-color: $border-color;
        box-shadow: none;
      }

      .price-sp {
        width: fit-content;
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        color: $text-color;

        &::before {
          display: none;
        }
      }

      .vld-bx {
        width: fit-content;
        margin: 0;
        font-size: 12px;
        color: #858585;
      }
    }
  }
}

.checkout-screen {
  .common-bx {
    padding: 24px;
    background-color: #f8f9fa;

    .order-details-box {
      .billing-box {
        padding: 24px;
        background-color: $white-color;
        border: 1px solid $border-color;
        border-radius: 8px;

        .billing-form {
          background-color: transparent;
          padding: 0;

          .flag-dropdown  {
            border-radius: 8px 0 0 8px;
            background-color: $white-color;
            border-color: $border-color;

            .selected-flag {
              border-radius: 8px 0 0 8px;
            }
          }

          .address {
            .address-box {
              border-radius: 8px;
              padding: 12px !important;
            }
          }
        }
      }
    }

    .quote-right {
      .qoute-box {
        border: 1px solid $border-color;
        border-radius: 8px;
        padding: 24px;
        background-color: $white-color;
        box-shadow: none;

        .pd-items {
          padding: 0;

          .item-box {
            border-radius: 8px;
            border: 1px solid $border-color;
            padding: 12px;

            .thumbnail {
              width: 48px;
              height: 48px;
              border: 1px solid $border-color;
              border-radius: 6px;
            }

            .quote-summary {
              font-size: 12px;
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 4px;
            }
          }
        }
      }
    }
  }
}

.order-details-screen {
  .intro-bar {
    .int-right {
      display: flex;
      gap: 16px;
    }
  }

  .md-container {
    padding: 24px;

    .common-bx {
      box-shadow: none;
      border-radius: 8px;

      .MuiAccordion-root {
        border-bottom: 1px solid $border-color;
        border-radius: 0px;
      }

      .qoute-version-tabs {
        border-bottom: 1px solid $border-color;
        .nav-link {
          font-size: 14px;
          font-weight: 400;

          &.active {
            border: 1px solid $border-color;
          }
        }
      }

      .table-div {
        border: 1px solid $border-color;
        border-radius: 8px;
        overflow: hidden;

        .qoute-version-table {
          border: none;

          .heading-row {
            height: fit-content;
            font-size: 12px;
            font-weight: 400;
            color: #858585;
            background-color: $white-color;
  
            th {
              background-color: $white-color;
              border: none;
              border-right: 1px solid $border-color;

              &:last-child {
                border-right: none;
              }
            }
          }

          tr {
            td {
              border: none;
              border-top: 1px solid $border-color;
              border-right: 1px solid $border-color;

              p, span {
                font-size: 14px;
              }

              &:last-child {
                border-right: none;
              }
            }
          }
        }
      }

      .feedback-box {
        border-bottom: none;

        #feedback {
          border: 1px solid $border-color;
          font-size: 14px;
          color: $text-color;
          border-radius: 8px !important;
          outline: none;

          &:focus-visible {
            border: 1px solid $primary-color !important;
          }
        }
      }

      .or-details-row {
        p.text {
          font-size: 14px;
          color: $text-color;
        }
      }

      .process-bar-bx {
        padding: 0;
        &::before {
          display: none;
        }

        .accordion-title {

          .sub-header {
            font-size: 16px;
            margin-bottom: 8px;
          }

          .arrow-icon {
            position: absolute;
            top: 0;
            right: 0px;
            height: 8px;
          }
        }

      }
    }
  }
}

.modal, .modal-backdrop, .offcanvas-backdrop, .offcanvas {
  z-index: 10000;
}