.addressModal {
  margin: 10% auto;
  width: 100%;
  height: auto;
  @media (max-width:600px) {
    height: 90vh;
    width: 355px;
    overflow: auto;
    top: 10px;
    left: 10px;
  }
  .title {
    font-weight: 500;
    font-size: 30px !important;
    line-height: 27px;
    margin-left: 38px;
    font-weight: bold;
    margin-top: 10px;
    color: #333333;
    padding: 10px 0 10px;
    @media (max-width:600px) {
      font-size: 16px;
      margin-top: 10px;
      margin-left: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .straightLine {
    height: 1px;
    width: 100%;
    background-color: #ced4da;
    margin-top: 10px;
    margin-bottom: 35px;
    @media (max-width:600px) {
      margin-bottom: 15px;
    }
  }
  .addressContainer {
    margin: 0px 40px 60px 40px;
    .name {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;

      @media (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 5px;
      }
    }
    // .first, .last{
    //   margin-bottom: 20px;
    // }
    input {
      width: 100%;
      // margin-bottom: 20px;
      border: 1px solid #eaedf0;
      border-radius: 3px;
      background: #ffffff;
      @media (max-width:600px) {
        margin-bottom: 10px;
      }
    }
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      margin-bottom: 2px;
      color: #333333;
      opacity: 0.7;
      margin-top:20px;
    }

    .postal {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      @media (max-width:600px) {
        grid-template-columns: 1fr;
        gap: 5px;
      }
    }
  }
  .bottomLine{
    height: 1px;
    width: 100%;
    background-color: #ced4da;
    margin-bottom: 21px;
    @media (max-width:600px) {
      margin-bottom: 15px;
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 40px 30px;
    @media (max-width:600px) {
      margin-right: 20px;
    }
    .backBtn {
      cursor: pointer;
      color: #333333;
      opacity: 0.7;
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
    .addBtn {
      margin-left: 20px;
      cursor: pointer;
      width: 100px;
      background: #2B96DC;
      border: 1px solid rgba(226, 228, 232, 0.4);
      box-shadow: 0px 8px 16px rgba(30, 52, 92, 0.1);
      border-radius: 4px;
      color: #ffffff;
      height: 36px;
    }
  }
}

.selectBox {
  width: 150px;
  margin-right: 30px;
  border: 1px solid #eaedf0;
}

.errorMessage{
  color: red;
  font-size: 12px;
  // font-family: ;
  line-height: 24px;
  text-align: left;
  margin: 5px 5px 0 5px;
}

.form-input {
  outline: none;
  height: 34px;
}