@mixin blue-btn {
  width: 100%;
  background: $primary-color;
  border-radius: 4px;
  padding: 10px 20px;
  color: $white-color;
  font-size: 15px;
  transition: 0.4s;
  text-decoration: none;
  border: 1px solid transparent;
  &:hover {
    background: #1f80bf;
    transition: 0.4s;
  }
}

@mixin border-btn {
  width: 100%;
  border: none;
  background: $white-color;
  border-radius: 4px;
  padding: 10px 20px;
  color: $primary-color;
  font-size: 15px;
  transition: 0.4s;
  text-decoration: none;
  border: 1px solid #dee2e6;
  &:hover {
    background: #edf2f4;
  }
}

@mixin white-btn {
  width: 100%;
  border: none;
  background: $white-color;
  border-radius: 4px;
  padding: 10px 20px;
  color: $secondary-color;
  opacity: 0.6;
  font-size: 15px;
  transition: 0.4s;
  text-decoration: none;
  border: 1px solid transparent;
  &:hover {
    background: #edf2f4;
  }
}

@mixin text-bar {
  border: 1px solid #eaedf0;
  border-radius: 3px;
  font-size: 14px;
  color: #333333;
  padding: 8px 12px;
}
